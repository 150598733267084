import { useState, useContext, useEffect, useRef } from 'react';
import { AppState } from '../../routes/Root';
import axios from 'axios';
import useGetUserData from '../../hooks/useGetUserData';
import { useLocation, useNavigate } from 'react-router-dom';
import Input, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import './ProfilePage.css';
import { useAuthToken } from '../../zustand/authStore';
import { useQueryClient } from '@tanstack/react-query';
import HeaderComponent from '../../components/HeaderComponent';
import TGLogin from '../../components/TGLogin/TGLogin';  
import OTPSender from '../../components/OTPSender';
import TelegramLoginButton from '../../components/TelegramLoginButton/TelegramLoginButton';
import OtpVerefications from '../../components/otoVerefications/otoVerefications'; 
import TelegramLoginButtons from '../../components/TelegramLoginButtons/TelegramLoginButtons';

export default function  ProfilePage() {
    const query = useQueryClient();
    const navigate = useNavigate(); 
    const location = useLocation();
    const refElem = useRef(); 
    const [token, setToken] = useState(null);
    const telegramUsername = localStorage.getItem('TelegramUsername'); 
    const { data: getUserData } = useGetUserData(token);
    const context = useContext(AppState);
    const [value, setValue] = useState(''); // Начальное значение пустая строка

    const [emailIsBeingEdited, setEmailIsBeingEdited] = useState(false);
    const [walletIsBeingEdited, setwalletIsBeingEdited] = useState(false);
    const [phoneIsBeingEdited, setPhoneIsBeingEdited] = useState(false);
    const [isOpenOtp, setIsOpenOtp] = useState(false); 
    const [compState, setCompState] = useState({
        timeouted: false,
        email: null, 
        saved_wallet_trc: null,
        counting: 0,
        wallet: null,
        lastName: null,
        firstName: null,
        middleName: null,
    });
    function timeout() {
        setTimeout(() => {
            setCompState({ ...compState, timeouted: false });
        }, 3000);
    }

    const handleOtpOpen = (type) => {
      setIsOpenOtp(true);
      setCompState((prevState) => ({
        ...prevState,
        otpType: type, // сохраняем тип, который передается в OTPSender
      }));
    };
    
    const handleOtpClose = () => setIsOpenOtp(false);

    useEffect(() => {
      const storedToken = localStorage.getItem("TokenBearer"); 
      if (storedToken) {
        setToken(storedToken);
      }
    }, []);
  

    console.log(compState.phone_number, 'arrr');
    console.log(value, 'arrr22');

    // function setProfile() {
    //     if (
    //         ((value && isValidPhoneNumber(value)) ||
    //             compState.email !== null ||
    //             compState.saved_wallet_trc !== null,
    //         compState.firstName !== null,
    //         compState.middleName !== null,
    //         compState.lastName !== null)
    //     ) {
    //         axios
    //             .post(
    //                 'https://api.sprite-ps.com/profile',
    //                 { ...compState, phone_number: value },
    //                 {
    //                     headers: {
    //                         Authorization: localStorage.getItem('TokenBearer'),
    //                     },
    //                 }
    //             )
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     context.dispatch({
    //                         type: 'success_popup',
    //                         payload: 'Data was successful changed',
    //                     });
    //                 }
    //                 query.invalidateQueries(['profile']);
    //             })
    //             .catch((error) => {
    //                 context.dispatch({
    //                     type: 'error_popup',
    //                     payload: error.response.data.detail[0].ctx.reason,
    //                 });
    //             });
    //     } else {
    //         context.dispatch({
    //             type: 'error_popup',
    //             payload: 'Enter valid phone number',
    //         });
    //     }
    //     setCompState({ ...compState, timeouted: true });
    //     timeout();
    // }



    function setProfile() {
      // Проверка, что хотя бы одно поле заполнено
      if (compState.firstName !== null || compState.lastName !== null) {
          // Отправка запроса только с тремя полями
          axios
              .post(
                  'https://api.sprite-ps.com/profile',
                  { 
                      first_name: compState.firstName, 
                      last_name: compState.lastName, 
                      // saved_wallet_trc: compState.saved_wallet_trc 
                  },
                  {
                      headers: {
                          Authorization: localStorage.getItem('TokenBearer'),
                      },
                  }
              )
              .then((response) => {
                  if (response.status === 200) {
                      context.dispatch({
                          type: 'success_popup',
                          payload: 'Data was successfully changed',
                          
                      });
                  }
                  query.invalidateQueries(['profile']);
              })
              .catch((error) => {
                  context.dispatch({
                      type: 'error_popup',
                      payload: error.response.data.detail[0].msg || 'Error updating profile',
                  });
              });
      } else {
          context.dispatch({
              type: 'error_popup',
              payload: 'Please provide at least one field to update.',
          });
      }
  
      // Ожидание перед повторным изменением
      setCompState({ ...compState, timeouted: true });
      timeout();
  }
   

    function backStory() {
        navigate('/', { replace: true });
    }
    useEffect(() => {
        if (getUserData !== null) {
            refElem.current.value = getUserData?.saved_wallet_trc ? '' : '';
        }
    }, [getUserData]);
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])


  // В родительском компоненте ProfilePage 
  if (isOpenOtp) {
    return <OtpVerefications inputType={compState.otpType} inputValue={compState.email} />;
  }
  
  

    return ( 
        <div className="profile block">
            <div className="profile_title">
                <HeaderComponent redirectToHome>Profile</HeaderComponent>
            </div>
            {/* <div className="profile_names-block">
                <div className="name">
                    <p>First Name:</p>
                    <p>{getUserData?.first_name}</p>
                </div>
                <div className="surname">
                    <p>Middle Name:</p>
                    <p>{getUserData?.middle_name}</p>
                </div>
                <div className="second-name">
                    <p>Last Name:</p>
                    <p>{getUserData?.last_name}</p>
                </div>
            </div> */}
            <div className="profile_contacts styled-block">
                <div className="profile_first-name ">
                    <p>First name:</p>
                    <input
                        type="text"
                        onChange={(e) => setCompState({ ...compState, firstName: e.target.value })}
                        className="invoice-input"
                        placeholder={getUserData?.first_name}
                    />
                </div>
                <div className="profile_middle-name ">
                    <p>Last name:</p> 
                    <input
                        type="text" 
                        onChange={(e) => setCompState({ ...compState, lastName: e.target.value })}
                        className="invoice-input"
                        placeholder={getUserData?.last_name} 
                    />
                </div>
               




 

                {/* <div className="profile_last-name ">
                    <p>Username TG:</p>
                    <input
                        type="text"
                        value={telegramUsername || ""} 
                        // onChange={(e) => setCompState({ ...compState, lastName: e.target.value })}
                        className="invoice-input"
                        // placeholder={getUserData?.last_name}
                        readOnly
                    />  
                 {!telegramUsername && ( 
        <div className="btn-change-one btn-anim"> 
            <TelegramLoginButtons /> 
        </div>
    )} 
                </div> 

 */}



<div className={`username-container ${getUserData?.tg_username ? 'has-telegram' : 'no-telegram'}`}>
  <div className="profile_last-name"> 
    <p>Username TG:</p>   
    <input 
      type="text"
      placeholder={getUserData?.tg_username || ""} 
    
      className="invoice-input"
      readOnly 
    />   
  </div>
  {!getUserData?.tg_username && <TelegramLoginButtons />}
</div>

                
                {/* <div className="profile_phone"> 
                    <p>Phone:</p>
                    <Input
                        className="invoice-input-extend"
                        placeholder={formatPhoneNumberIntl(getUserData?.phone_number)}
                        value={value}
                        onChange={setValue}
                    /> 
                    <OTPSender btnNum={2} type={'phone'} mail_or_phone={value} token={token} />
                </div> */}
 
 <div className="profile_phone-container" style={{ paddingRight: value ? '55px' : '0px' }}>
  <div className="profile_phone">
    <p>Phone:</p>
    <Input 
      className="invoice-input-extend"
      placeholder={formatPhoneNumberIntl(getUserData?.phone_number)}
      value={value} 
      onChange={
        setValue}
    />
 
  </div>  
  {value && ( // Проверка, если значение в поле телефона не пустое
    <OTPSender btnNum={2} type={'phone'} mail_or_phone={value} token={token}       setIsOpenOtp={() => handleOtpOpen('phone')}   />
  )}
</div>


<div className={`profile_mail-container  ` } style={{ paddingRight: compState.email ? '55px' : '0px' }}> 
  <div className="profile_mail">
    <p>Email:</p> 
    <input
      type="text"
      onChange={(e) =>
        { setCompState({ ...compState, email: e.target.value });setEmailIsBeingEdited(true);}}
      className="invoice-input"
      placeholder={getUserData?.email}
      
    />
   
  </div>
  {emailIsBeingEdited && compState.email && ( 
  <OTPSender
      btnNum={3}
      type={'email'}
      mail_or_phone={compState.email}
      token={token}
      setIsOpenOtp={() => handleOtpOpen('email')} 
    /> 
  )}
</div>


<div className={`profile_mail-containers  ` } style={{ paddingRight: compState.saved_wallet_trc ? '65px' : '0px' }}> 
                <div className="profile_wallet">
                    <p>Wallet*:</p>
                    <div className="iprofile-currency-block">
                        <input
                            className="invoice-input"
                            ref={refElem}
                            // value={compState.saved_wallet_trc || ''}
                            onChange={(e) =>
                              { setCompState({ ...compState, saved_wallet_trc: e.target.value  });setwalletIsBeingEdited(true);}}
                            type="text"
                            
                            placeholder={getUserData?.saved_wallet_trc || ""} 
                        />
                        <span className="withdraw-warning">*USDT (TRC20)</span>
                    </div>
                </div>
                {walletIsBeingEdited && compState.saved_wallet_trc && (
  <OTPSender
      btnNum={4}
      mail_or_phone={compState.saved_wallet_trc}
      token={token}
      setIsOpenOtp={handleOtpOpen}  
    /> 
  )}
</div>



                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {compState.timeouted ? (
                        <button className="btn-done">Please wait</button>
                    ) : (
                        <button className="btn-done btn-anim" onClick={() => setProfile()}>
                            Accept Change
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
