import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { AppState } from '../../routes/Root';
import { useLocation } from 'react-router-dom';
import useGetUserData from '../../hooks/useGetUserData';
import ScrollDownListOnlyCurrency from '../../components/scrollDownListOnlyCurrency/scrollDownListOnlyCurrency';
import ScrollDownListProjects from '../../components/scrollDowsListProjects/scrollDowsListProjects';
import ScrollDownList from '../../components/scrollDownList/scrollDownList';
import checked from '../../img/checked.svg';
import unchecked from '../../img/unchecked.svg';
import InfoIcon from '../../components/infoIcon/infoIcon';
import OrderType from '../../components/ordersType/orderType';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import './InvoicePage.css';
import { useAuthToken } from '../../zustand/authStore';
import useGetServices from '../../hooks/useGetServices';
import useGetCardFilters from '../../hooks/useGetCardFilters';
import { toast } from 'react-toastify';
import HeaderComponent from '../../components/HeaderComponent';

export default function InvoicePage() {
    axiosRetry(axios, {
        retries: 2,
        retryDelay: (retryCount) => {
            console.log('retry attempt : ', retryCount);
            return retryCount * 2000;
        },
    });
    const navigate = useNavigate();
    const location = useLocation();
    const pageType = location.state; 
    const context = useContext(AppState);
    // const { token } = useAuthToken((state) => state);
    const token = localStorage.getItem('TokenBearer');
    const getData = useGetServices(token);
    const [serviceId, setServiceId] = useState(getData?.data?.id); 
    console.log(serviceId, 'SERVICE ID ');

    const getFilters = useGetCardFilters(token, serviceId);
    console.log('getData', getData.data); 
    const paymentArrayInvoice = [
        ['tether', ['USDT']],
        ['ethereum', ['ETH']],
        ['tron', ['TRX']], 
    ];
    const paymentArrayInvoiceOrder = { filters: ['USD', 'EUR', 'USDT', 'ETH', 'TRX'] };
    console.log('rendered');
    const defaultState = {
        productName: null,
        myProject: null,
        activeCurrency: null,
        activePayment: null,
        email: '',
        total: null,
        tag: false,
        payment: false,
        link: false,
        gate: true,
        orderCheck: false,
        myProjects: false,
        tagValue: '',
        timeouted: false,
    };
    const [getAllCards, setAllCards] = useState({});
    const [compState, setCompState] = useState({
        productName: '',
        activeCurrency: null,
        activePayment: null,
        email: '',
        total: null,
        tag: false,
        payment: false,
        link: false,
        gate: false,
        activeProject: false,
        orderTypeSelected: null,
        orderCheck: false,
        myProject: null,
        myProjects: false,
        myProjectsArray: null,
        tagValue: '',
        apiKey: null,
        url: '',
        cardsArray: [],
        selectedProjectId: null,
    });
    const currencyArray = ['BTC', 'ETH', 'TNH', 'PKD'];
    const paymentArray = ['PayPal', 'Visa', 'MasteCard'];
    const myProjects = [
        'Car Wash On Baker.st',
        'Pottering west',
        'Tabaco Cargo International',
        'AirLine HonkHonk',
    ];
    const paymentMethod = [
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSk1F9cEGz48s1DVh4rnUm6KiG-v8r6QKasg&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJhgr7kVujdzrYKHAbwGRRFvvcrpx-zCPRNQ&s',
        'https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg',
    ];
    const imgArr = [
        'https://s2.coinmarketcap.com/static/img/coins/32x32/1.png',
        'https://s2.coinmarketcap.com/static/img/coins/32x32/1027.png',
        'https://s2.coinmarketcap.com/static/img/coins/32x32/1958.png',
        'https://s2.coinmarketcap.com/static/img/coins/32x32/6636.png',
    ];
    function shareButtonFunc(event) {
        event.preventDefault();
        window.navigator.clipboard.writeText(compState.url);
        if (navigator.share) {
            navigator
                .share({
                    url: compState.url,
                })
                .catch(console.error);
        } else {
            navigator.clipboard.writeText(compState.url);
        }
    }
    function copyLink(event) {
        event.preventDefault();
        window.navigator.clipboard.writeText(compState.url);

        context.dispatch({ type: 'success_popup', payload: 'Link succesful copied.' });
    }
    function backStory() {
        navigate('/', { replace: true });
    }
    function nextStep() {
        setCompState({ ...compState, step: compState.step + 1 });
    }
    function timeout() {
        setTimeout(() => {
            setCompState({ ...compState, timeouted: false });
        }, 3000);
    }
    function prevStep() {
        setCompState({ ...compState, step: compState.step - 1 });
    }
    function selectCurrencyProp(value) {
        setCompState({ ...compState, activeCurrency: value });
    }
    function selectPaymentProp(element) {
        setCompState({
            ...compState,
            activePayment: element.payment_system.toLowerCase(),
            activeCurrency: element.currency,
            payment: false,
        });
    }
    function selectPaymentPropOrder(element) {
        setCompState({
            ...compState,
            activePayment: element[0],
            activeCurrency: element[1][0],
            payment: false,
        });
    }
    function selectPaymentOnlyCyrrency(currency) {
        setCompState({ ...compState, activeCurrency: currency, payment: false });
    }
    function reciveLink() {
        setCompState({ ...compState, link: !compState.link });
    }
    function changeWithdrae(e) {
        const inputValue = e.target.value;
        if (/^[0-9.]{0,16}$/.test(inputValue)) {
            setCompState({ ...compState, total: inputValue });
        }
    }
    const handleChangeProductName = (e) => {
        const inputValue = e.target.value;
        if (/^.{0,30}$/.test(inputValue)) {
            setCompState({ ...compState, productName: inputValue });
        }
    };
    function chekForGate(e) {
        e.stopPropagation();
        if (compState.gate === false) {
            setCompState({ ...compState, gate: true });
        } else {
            setCompState({ ...compState, gate: false });
        }
    }
    function chekForTag() {
        setCompState({ ...compState, tag: !compState.tag, tagValue: '' });
    }
    function chekForPayment(e) {
        if (compState.myProject === null) {
            context.dispatch({ type: 'error_popup', payload: 'Select Project' });
        } else {
            setCompState({ ...compState, payment: !compState.payment });
        }
    }
    function makeTag(e) {
        setCompState({ ...compState, tagValue: e.target.value });
    }
    function chekForProjects(e) {
        setCompState({ ...compState, myProjects: !compState.myProjects });
    }
    function selectMyProject(name, apiKey, id) {
        setCompState({
            ...compState,
            myProject: name,
            apiKey: apiKey,
            myProjects: false,
            selectedProjectId: id,
        });
    }
    function selectOrderType(value) {
        setCompState({
            ...compState,
            orderTypeSelected: location.state,
            productName: '',
            activeCurrency: null,
            activePayment: null,
            email: '',
            total: null,
            tag: false,
            payment: false,
            link: false,
            gate: false,
            orderCheck: false,
            myProject: null,
            activeProject: false,
            myProjects: false,
            myProjectsArray: getData?.data,
            tagValue: '',
            apiKey: null,
            url: '',
            cardsArray: [],
            selectedProjectId: null,
        });
    }
    function selectCurrencyRange() {
        switch (compState.activeCurrency) {
            case 'ETH':
                return 'Between  0.0004 and 17 ETH';
            case 'TRX':
                return 'Between  10 and 309000 TRX ';
            case 'USD':
                return 'Between 1 and 50000 USD';
            case 'USDT':
                return 'Between 1 and 50000 USDT';
            default:
                console.warn('No Currency selected');
        }
    }
    function ckeckForOrderType() {
        setCompState({ ...compState, orderCheck: !compState.orderCheck });
    }
    function createInvoice() {
        const body = {
            api_key: compState.apiKey,
            currency: compState.activeCurrency,
            amount: compState.total,
            title: compState.productName,
            tag: '',
            buyer_email: compState.email,
            payment_system: compState.activePayment?.toLowerCase(),
        };
        if (body.api_key === null || body.currency === null || body.amount === null) {
            context.dispatch({ type: 'error_popup', payload: 'Make sure your select all fields.' });
            // setCompState({ ...compState, timeouted: true })
            // timeout()
        } else {
            setCompState({ ...compState, timeouted: true });
            axios
                .post('https://api.sprite-ps.com/invoices/create', body, {
                    headers: {
                        Authorization: localStorage.getItem('TokenBearer'),
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setCompState({
                            ...compState,
                            url: response.data.url,
                            link: !compState.link,
                            timeouted: false,
                        });
                    } else {
                        context.dispatch({ type: 'error_popup', payload: response.status });
                        setCompState({ ...compState, timeouted: false });
                    }
                })
                .catch((error) => {
                    console.log(error.status);
                    setCompState({ ...compState, timeouted: false });
                    context.dispatch({
                        type: 'error_popup',
                        payload: error.response.data.detail[0].msg,
                    });
                    timeout();
                });
        }
    }
    console.log();
    function createOrder() {
        function checkingGate() {
            if (compState.gate) {
                return {
                    api_key: compState.apiKey,
                    currency: compState.activeCurrency,
                    amount: compState.total,
                    title: compState.productName,
                    buyer_email: compState.email,
                    payment_system: compState.activePayment,
                };
            } else {
                return {
                    api_key: compState.apiKey,
                    amount: compState.total,
                    title: compState.productName,
                    currency: compState.activeCurrency,
                };
            }
        }
        setCompState({ ...compState, timeouted: true });
        axios
            .post('https://api.sprite-ps.com/orders/create', checkingGate(), {
                headers: {
                    Authorization: localStorage.getItem('TokenBearer'),
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setCompState({
                        ...compState,
                        url: response.data.url,
                        link: !compState.link,
                        timeouted: false,
                    });
                } else {
                    context.dispatch({ type: 'error_popup', payload: response.status });
                    setCompState({ ...compState, timeouted: false });
                }
            })
            .catch((error) => {
                setCompState({ ...compState, timeouted: false });
                if (error.status === 422) {
                    context.dispatch({
                        type: 'error_popup',
                        payload: error.response.data.detail[0].msg,
                    });
                    // timeout()
                } else if (error.status === 500) {
                    context.dispatch({ type: 'error_popup', payload: 'Server error.' });
                    // timeout()
                } else {
                    context.dispatch({
                        type: 'error_popup',
                        payload: 'Make sure your select all fields.',
                    });
                    // timeout()
                }
            });
    }
    function PaymentOrderSwitch() {
        if (compState.gate && compState.payment) {
            return (
                <ScrollDownListOnlyCurrency
                    array={paymentArrayInvoice}
                    selecting={selectPaymentPropOrder}
                    closingComponent={chekForPayment}
                    id={compState.selectedProjectId}
                />
            );
        } else if (!compState.gate && compState.payment) {
            return (
                <ScrollDownList
                    array={getFilters?.data?.cards}
                    selecting={selectPaymentProp}
                    closingComponent={chekForPayment}
                    id={serviceId}
                />
            );
        }
    }
    function switchInvoice() {
        switch (location.state) {
            case 'INVOICE':
                return (
                    <div
                        className="block styled-block invoice-selecting-params"
                        style={{ padding: '25px 25px 5px 25px' }}
                    >
                        <div>
                            <h3>Select Project:</h3>
                            <span className="checkForPayment" onClick={(e) => chekForProjects(e)}>
                                <span>
                                    <span className="myProjectsSelecting">
                                        {compState.myProject === null ? (
                                            <span className="gradient-element animate-txt">
                                                Choose Projects
                                            </span>
                                        ) : (
                                            compState.myProject
                                        )}
                                    </span>
                                </span>
                            </span>
                            {compState.myProjects ? (
                                <ScrollDownListProjects
                                    array={getData?.data?.data}
                                    setServiceId={setServiceId}
                                    selecting={selectMyProject}
                                    closingComponent={chekForProjects}
                                />
                            ) : null}
                        </div>
                        <div>
                            <h3>Enter product name:</h3>
                            <div className="">
                                <div className="invoice-currency-block">
                                    <input
                                        type="text"
                                        className="invoice-input"
                                        value={compState.productName}
                                        disabled={compState.timeouted}
                                        onChange={(e) => handleChangeProductName(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        {compState.payment ? (
                            <ScrollDownList
                                array={getFilters?.data?.cards}
                                selecting={selectPaymentProp}
                                closingComponent={chekForPayment}
                                id={compState.selectedProjectId}
                            />
                        ) : null}
                        <div>
                            <h3> Select Amount & Currency:</h3>
                            <span>{selectCurrencyRange()}</span>
                            <div className="">
                                {compState.activeCurrency === null ? (
                                    <div className="invoice-currency-block">
                                        <input
                                            className="invoice-input"
                                            disabled
                                            value={compState.total}
                                            type="text"
                                            onChange={(e) => changeWithdrae(e)}
                                        />
                                        {getFilters && getFilters.data && (
                                            <span
                                                onClick={(e) => chekForPayment(e)}
                                                className="invoice-input_selecting"
                                            >
                                                {compState.activeCurrency === null ? (
                                                    <span className="gradient-element animate-txt">
                                                        Choose currency
                                                    </span>
                                                ) : (
                                                    compState.activeCurrency
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="invoice-currency-block">
                                        <input
                                            className="invoice-input"
                                            value={compState.total}
                                            pattern="/\d*/"
                                            type="text"
                                            disabled={compState.timeouted}
                                            onChange={(e) => changeWithdrae(e)}
                                        />
                                        <span
                                            onClick={(e) => chekForPayment(e)}
                                            className="invoice-input_selecting"
                                        >
                                            {compState.activeCurrency === null ? (
                                                <span className="gradient-element">chose</span>
                                            ) : (
                                                compState.activeCurrency
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {compState.link ? (
                            <div className="invoce-link-share">
                                <a
                                    className="invoce-formation"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => copyLink(e)}
                                >
                                    {compState.url}
                                </a>
                                <span
                                    className="btn-share"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => shareButtonFunc(e)}
                                >
                                    Share
                                </span>
                            </div>
                        ) : compState.timeouted ? (
                            <button className="btn-done">Please wait</button>
                        ) : (
                            <button className="btn-done btn-anim" onClick={() => createInvoice()}>
                                Accept
                            </button>
                        )}
                        <div></div>
                    </div>
                );
            case 'ORDER':
                return (
                    <div
                        className="block styled-block invoice-selecting-params"
                        style={{ padding: '25px 25px 5px 25px' }}
                    >
                        <div>
                            <h3>Select Project:</h3>
                            <span className="checkForPayment" onClick={(e) => chekForProjects(e)}>
                                <span>
                                    <span className="myProjectsSelecting animate-txt">
                                        {compState.myProject === null ? (
                                            <span className="gradient-element animate-txt">
                                                First Chose Project
                                            </span>
                                        ) : (
                                            compState.myProject
                                        )}
                                    </span>
                                </span>
                            </span>
                            {compState.myProjects && getData && getData.data ? (
                                <ScrollDownListProjects
                                    array={getData.data.data}
                                    selecting={selectMyProject}
                                    closingComponent={chekForProjects}
                                    setServiceId={setServiceId}
                                />
                            ) : null}
                        </div>

                        <div>
                            <h3>Enter product name:</h3>
                            <div className="">
                                <div className="invoice-currency-block">
                                    <input
                                        type="text"
                                        className="invoice-input"
                                        disabled={compState.timeouted}
                                        onChange={(e) => handleChangeProductName(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        {PaymentOrderSwitch()}
                        <div>
                            <h3> Select Amount & Currency:</h3>
                            <span>{selectCurrencyRange()}</span>
                            <div className="">
                                {compState.activeCurrency === null ? (
                                    <div className="invoice-currency-block">
                                        <input
                                            className="invoice-input"
                                            disabled
                                            value={compState.total}
                                            type="text"
                                            onChange={(e) => changeWithdrae(e)}
                                            maxLength={10}
                                        />
                                        {getFilters && getFilters.data && (
                                            <span
                                                onClick={(e) => chekForPayment(e)}
                                                className="invoice-input_selecting"
                                            >
                                                {compState.activeCurrency === null ? (
                                                    <span className="gradient-element">
                                                        Choose currency
                                                    </span>
                                                ) : (
                                                    compState.activeCurrency
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="invoice-currency-block">
                                        <input
                                            className="invoice-input"
                                            value={compState.total}
                                            disabled={compState.timeouted}
                                            pattern="/\d*/"
                                            type="text"
                                            onChange={(e) => changeWithdrae(e)}
                                        />
                                        <span
                                            onClick={(e) => chekForPayment(e)}
                                            className="invoice-input_selecting"
                                        >
                                            {compState.activeCurrency === null ? (
                                                <span className="gradient-element">
                                                    Choose Currency
                                                </span>
                                            ) : (
                                                compState.activeCurrency
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {compState.gate ? (
                                <div>
                                    <h3>Enter Email:</h3>
                                    <div className="">
                                        <div className="invoice-currency-block">
                                            <input
                                                type="text"
                                                className="invoice-input"
                                                disabled={compState.timeouted}
                                                onChange={(e) =>
                                                    setCompState({
                                                        ...compState,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <span className="checkForPayment">
                                <span>
                                    <span
                                        className="ckeck-box"
                                        onClick={(e) => chekForGate(e)}
                                        style={{
                                            backgroundImage: `url(${
                                                compState.gate ? checked : unchecked
                                            })`,
                                        }}
                                    ></span>
                                    <span className="invoice_gate-info">
                                        <span>use gate</span>{' '}
                                    </span>
                                </span>
                            </span>
                        </div>
                        {compState.link ? (
                            <div className="invoce-link-share">
                                <a
                                    className="invoce-formation"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => copyLink(e)}
                                >
                                    {compState.url}
                                </a>
                                <span
                                    className="btn-share"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => shareButtonFunc(e)}
                                >
                                    Share
                                </span>
                            </div>
                        ) : compState.timeouted ? (
                            <button className="btn-done">Please wait</button>
                        ) : (
                            <button className="btn-done btn-anim" onClick={() => createOrder()}>
                                Accept
                            </button>
                        )}
                        <div></div>
                    </div>
                );
            default:
                console.warn('Error in invoice switch');
        }
    }
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])
    // useEffect(() => {
    //     if (getData !== null) {
    //         setCompState({
    //             ...compState,
    //             myProjectsArray: getData.data,
    //         })
    //         console.log(compState.myProjectsArray)
    //     }
    // }, [getData.data])
    return (
        <div className="mainFrame-invoice-formation block">
            <div className="mainFrame-invoice-header"> 
                {location.state === 'ORDER' ? (
                    <HeaderComponent redirectToHome>Orders Formation</HeaderComponent>
                ) : (
                    <HeaderComponent redirectToHome>Invoices</HeaderComponent>  
                )}{' '}
                {location.state === 'INVOICE' && (
                    <button
                        className="balance-btn clickable"
                        onClick={() => navigate('/all_invoices')}
                    >
                        All Invoices
                    </button>
                )}
            </div>
            <div className="invoice-context block styled-block">
                <ul>
                    {compState.orderTypeSelected === 'ORDER' && compState.gate ? (
                        <li>
                            <span>Email:</span>
                            <span></span>
                            <span>{compState.email}</span>
                        </li>
                    ) : null}
                    <li>
                        <span>Product Name:</span>
                        <span></span>
                        <span>{compState.productName}</span>
                    </li>
                    <li>
                        <span>Amount & Currency:</span>
                        <span></span>{' '}
                        <span>
                            {compState.total === null ? '' : compState.total}{' '}
                            {compState.activeCurrency}
                        </span>
                    </li>
                    <li>
                        <span>Payment Method: </span>
                        <span></span>
                        <span className="PaymentMethod-invoice">{compState.activePayment}</span>
                    </li>
                </ul>
            </div>
            {/* <div className="order-type styled-block block">
                <h3 onClick={(e) => ckeckForOrderType(e)}>Select Order Type:</h3>
                <span onClick={(e) => ckeckForOrderType(e)}>
                    <span className="myProjectsSelecting">{compState.orderTypeSelected === null ? "Select" : compState.orderTypeSelected}</span>
                </span>

                {compState.orderCheck ? <OrderType selecting={selectOrderType} closingComponent={ckeckForOrderType} /> : null}
            </div> */}
            {switchInvoice()}
        </div>
    );
}
