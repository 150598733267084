import Back from '../BackArrow';
import useTelegramWebview from '../../hooks/useTelegramWebview';
import styles from './HeaderComponent.module.scss';

function HeaderComponent({ children , redirectToHome = false }) { 
    const isTgApp = useTelegramWebview();
 
    return ( 
        <div className={styles.header}>  
            {!isTgApp && <Back  redirectToHome={redirectToHome}  />} 
            <h1 className={styles.txt}>{children}</h1>
        </div>
    );
}

export default HeaderComponent;
