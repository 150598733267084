import { useContext, useEffect } from "react"
import { AppState } from "../../routes/Root"
import { useState } from "react"
import axios from "axios"
import "./scrollDownList.css"

const filters = ["ALL", "BANK", "PAYPAL", "YANDEX", "CRYPTO"]
const banksFilters = ["VISA", "MASTERCARD", "МИР"]  
const paypal = ["PayPal"]
const currency = ["RUB", "USD", "KZT", "EUR", "USDT", "TRX", "ETH"]
const cards = JSON.parse('[{"bank_name":"Alfa-Bank","payment_system":"МИР","currency":"RUB"},{"bank_name":"VTB","payment_system":"МИР","currency":"RUB"},{"bank_name":"Tinkoff","payment_system":"МИР","currency":"RUB"},{"bank_name":"Yandex","payment_system":"Yandex","currency":"RUB"},{"bank_name":"Permata","payment_system":"VISA","currency":"USD"},{"bank_name":"Permata","payment_system":"VISA","currency":"KZT"},{"bank_name":"Permata","payment_system":"VISA","currency":"EUR"},{"bank_name":"BNI","payment_system":"MASTERCARD","currency":"USD"},{"bank_name":"BNI","payment_system":"MASTERCARD","currency":"KZT"},{"bank_name":"BNI","payment_system":"MASTERCARD","currency":"EUR"},{"bank_name":"MyBank","payment_system":"MASTERCARD","currency":"USD"},{"bank_name":"MyBank","payment_system":"MASTERCARD","currency":"KZT"},{"bank_name":"MyBank","payment_system":"MASTERCARD","currency":"EUR"},{"bank_name":"BritAma","payment_system":"MASTERCARD","currency":"USD"},{"bank_name":"BritAma","payment_system":"MASTERCARD","currency":"KZT"},{"bank_name":"BritAma","payment_system":"MASTERCARD","currency":"EUR"},{"bank_name":"USDT (TRC-20)","payment_system":"Tether","currency":"USDT"},{"bank_name":"PayPal","payment_system":"PayPal","currency":"USD"},{"bank_name":"PayPal","payment_system":"PayPal","currency":"EUR"},{"bank_name":"TRX (Tron)","payment_system":"Tron","currency":"TRX"},{"bank_name":"ETH (ERC-20)","payment_system":"Ethereum","currency":"ETH"}]')


export default function ScrollDownList({ array, selecting, closingComponent, id }) {
    const context = useContext(AppState)
    const [compState, setCompState] = useState({
        dropDownActive: true,
        selectedCurrency: "",
        selectFilter: "all",
        filteredArray: [],
        allCards: [],
    })
    console.log('array of available cards scrolldonwlist ', array)
    
    function getFilterTitle() {
        let tempData = []
        for (let element in array) {
            tempData.push(element)
        }
        console.log(tempData)
        setCompState({ ...compState, filteredArray: tempData, selectFilter: tempData[0] })
    }
    // function closeModal(e) {
    //     e.stopPropagation()
    //     context.dispatch({ type: "close_invose" })
    // }

    // function selectClick(value) {
    //     selecting(value)
    // }
    function selectAndClose(e, element) {
        closingComponent(e)
        selecting(element)
    }

    // function filteringCardArray(value, filter) {
    //     const formatedArray = []
    //     for (let x = 0; x < value.length; x++) {
    //         for (let y = 0; y <= filter.length; y++) {
    //             if (value[x].payment_system.includes(filter[y])) {
    //                 formatedArray.push(value[x])
    //             }
    //         }
    //     }
    //     return formatedArray
    // }

    function selectedFilter(value, e) {
        setCompState({
            ...compState, selectFilter: value
        })
    }

    // function switchFilters(value) {
    //     if (compState.selectFilter === "all") {
    //         axios.get(`https://api.sprite-ps.com/available_service_cards?service_id=${id}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem("TokenBearer")
    //             }
    //         })
    //             .then(response => setCompState({ ...compState, allCards: response.data.cards }))
    //             .catch(error =>
    //                 console.log(error.status))
    //     } else {
    //         axios.get(`https://api.sprite-ps.com/available_service_cards?service_id=${id}&ps_filter=${value}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem("TokenBearer")
    //             }
    //         })
    //             .then(response => setCompState({ ...compState, allCards: response.data.cards }))
    //             .catch(error =>
    //                 console.log(error.status))
    //     }
    // }
    // useEffect(() => {
    //     if (id !== null && compState.selectFilter === "all") {
    //         axios.get(`https://api.sprite-ps.com/available_service_cards?service_id=${id}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem("TokenBearer")
    //             }
    //         })
    //             .then(response => setCompState({ ...compState, allCards: response.data.cards }))
    //             .catch(error =>
    //                 console.log(error.status))
    //     } else {
    //         axios.get(`https://api.sprite-ps.com/available_service_cards?service_id=${id}&ps_filter=${compState.selectFilter}`, {
    //             headers: {
    //                 Authorization: localStorage.getItem("TokenBearer")
    //             }
    //         })
    //             .then(response => setCompState({ ...compState, allCards: response.data.cards }))
    //             .catch(error =>
    //                 console.log(error.status))
    //     }
    // }, [id, compState.selectFilter])
    function switchFilters() {
        console.log(array, compState.selectFilter)
        // return (
        //     array[compState.selectFilter].map((element, index) => {
        //         <li key={index} onClick={(e) => selectAndClose(e, element)}>{element.bank_name}</li>
        //     })
        // )
    }
    useEffect(() => {
        getFilterTitle()
    }, [])
    return (
        <div className="scroll-down-bg" >
            <div className="scroll-down-close">
                <span onClick={(e) => closingComponent(e)}>+</span>
            </div>
            {/* <div className="scroll-down-filter-list">
                {compState.filteredArray !== undefined ?
                    compState.filteredArray.map((element, index) => {
                        return (
                            <span key={index} onClick={(e) => selectedFilter(element)} style={{ textTransform: "uppercase" }}>{element}</span>
                        )
                    }) : 
                    null
                }

            </div> */}
            <ul className="scroll-down-list block">
                {/* {compState.filteredArray > 0 ?
                    compState.allCards.map((element, index) => {
                        console.log(element)
                        return <li key={index} onClick={(e) => selectAndClose(e, element)}>{element.bank_name}</li>
                    }) : null
                } */}
                {console.log(array[compState.selectFilter])}
                {array[compState.selectFilter] !== undefined ?
                    array[compState.selectFilter].map((element, index) => {
                        console.log(element) 
                        return <li className="li-scrolls"  key={index} onClick={(e) => selectAndClose(e, element)}>{element.bank_name}</li>
                    }) : null
                }
            </ul>
        </div >
    )
}