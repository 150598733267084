import { useNavigate } from 'react-router-dom';
import arrow from '../../img/arrowRight.svg';
import styles from './BackArrow.module.scss';

const Back = ({ redirectToHome = false }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (redirectToHome) {
            navigate('/');
        } else {
            navigate(-1);
        }
    };

    return (
        <div onClick={handleClick} className={styles.headerContainer}>
            <img className={styles.arrow} src={arrow} alt="back" loading="lazy" />
        </div>
    );
};

export default Back;
 