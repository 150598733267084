import axiosInstance from '../axios';
import { BASE_URL } from '../../static/static';
import { useAuthToken } from '../../zustand/authStore';

export const login = async (data) => {
    console.log('data', data);
    const response = await axiosInstance.post(BASE_URL + '/sign_in', {
        login_type: data.loginType,
        login: data.emailOrPhone,
        password: data.password,
    });
    console.log('response', response.headers['authorization']);
    const token = response.headers['authorization'];
    localStorage.setItem('TokenBearer', token);
    useAuthToken.getState().useSetToken(token);

    console.log('response data', response.data);
    return response.data;
};

export const register = async (data) => {
    console.log(' sign up data', data);
    const response = await axiosInstance.post(BASE_URL + '/sign_up', {
        login_type: data.loginType,
        login: data.emailOrPhone,
        password: data.password,
    });
    return response.data;
};

export const reset_password = async (data) => {
    console.log('data', data);
    const response = await axiosInstance.post(BASE_URL + '/reset_password', {
        login_type: data.loginType,
        login: data.emailOrPhone,
        password: data.password,
    });
    const token = response.headers['authorization'];
    localStorage.setItem('TokenBearer', token);
    useAuthToken.getState().useSetToken(token);
    return response.data;
};

export const resend_otp = async (data) => {
    const response = await axiosInstance.post(`${BASE_URL}/resend_otp`, {
        otp_type: data.loginType,
        otp_login: data.emailOrPhone,
    });
    return response.data;
};

export const confirm_otp = async (data) => {
    const response = await axiosInstance.post(`${BASE_URL}/confirm_otp`, {
        otp_type: data.loginType,
        otp_login: data.emailOrPhone,
        otp: data.otp,
        reset_password: data.reset_password,
    });

    const token = response.headers['authorization'];
    localStorage.setItem('TokenBearer', token);
    useAuthToken.getState().useSetToken(token);
    return response.data; 
};

export const confirm_set_phone_or_email = async (data) => {
  const response = await axiosInstance.post(`${BASE_URL}/confirm_set_phone_or_email`, {
    otp_type: data.otp_type,
    otp: data.otp, 
 });
 

  console.log("Server response:", response.data);
  return response.data;
};

