import { useState } from 'react';
import styles from './ChoozeMenu.module.scss';
const ChoozeMenu = ({ changeTab }) => {
    const [active, setActive] = useState('info');
    const chngTab = (txt) => {
        setActive(txt);
        changeTab(txt);
    };
    return (
        <div className={styles.choozeMenu}>
            <div className="">
                <span
                    className="av-Coins"
                    onClick={() => {
                        chngTab('info');
                    }}
                >
                    All projects
                </span>
                <span className={active === 'info' ? styles.activeTab : null}></span>
            </div>
            <div
                className=""
                onClick={() => {
                    chngTab('create');
                }}
            >
                <span className="av-portfolio">Create Project</span>
                <span className={active === 'create' ? styles.activeTab : null}></span>
            </div>
        </div>
    );
};

export default ChoozeMenu;
