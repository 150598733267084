import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import { useMutation } from "@tanstack/react-query";
import { login } from "../../api/auth/auth";
import TelegramLoginButton from "../TelegramLoginButton/TelegramLoginButton";
import ForgotPassword from "../ForgotPassword/ForgotPassword";

import eyeOn from "../../assets/eyePassword/eyeOn.svg";
import eyeOff from "../../assets/eyePassword/eyeOff.svg";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [inputType, setInputType] = useState("unknown");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);

  console.log(inputType);
 
  // Регулярные выражения для проверки
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+?[1-9]\d{9,14}$/;

  const loginMutation = useMutation({
    mutationKey: ["login"],
    mutationFn: login,
    retry: (failureCount, error) => {
        // Проверяем, если в ответе ошибка detail
        const errorMessage = error.response?.data?.detail;
        if (errorMessage) {
            toast.error(errorMessage, { autoClose: 3000 });
            return false;  // Прерываем ретрай
        }
        if (failureCount < 3) { 
            // toast.error('Something went wrong. Retrying...', { autoClose: 3000 });
            return true;  // Повторяем попытку
        }
        return false;  // Если не нужно пробовать повторно
    },
    onSuccess: (data) => {
        toast.success("Sign in successful", { autoClose: 3000 });
        navigate("/", { replace: true });
    },
    onError: (error) => {
        // Это место сработает только если нет поля detail в ошибке
        const errorMessage = error.response?.data?.detail;
        if (!errorMessage) {
            toast.error('Something went wrong. Please try again.', { autoClose: 3000 });
        }
    },
});


  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    // Определение типа ввода
    if (emailRegex.test(value)) {
      setInputType("email");
    } else if (phoneRegex.test(value) && value.length > 0) {
      setInputType("phone");
    } else {
      setInputType("unknown");
    }
  };

  // const validateInput = () => {
  //   if (!inputValue || !password) {
  //     toast.error("Please enter login and password", { autoClose: 3000 });
  //     return false;
  //   }

  //   if (inputType === "email") {
  //     if (!emailRegex.test(inputValue)) {
  //       toast.error("Please enter a valid email", { autoClose: 3000 });
  //       return false;
  //     }
  //   }

  //   if (inputType === "phone") {
  //     if (!phoneRegex.test(inputValue)) {
  //       toast.error("Please enter a valid phone number", { autoClose: 3000 });
  //       return false;
  //     }
  //     if (inputValue.length < 10 || inputValue.length > 15) {
  //       toast.error("Phone number must be between 10 and 15 digits", {
  //         autoClose: 3000,
  //       });
  //       return false;
  //     }
  //   }

  //   if (inputType === "unknown") {
  //     toast.error("Please enter a valid email or phone number", {
  //       autoClose: 3000,
  //     });
  //     return false;
  //   }

  //   return true;
  // };

  // const handleSubmit = (e) => {
  //   if (validateInput()) {
  //     const credentials = {
  //       loginType: inputType,
  //       emailOrPhone: inputValue,
  //       password,
  //     };

  //     loginMutation.mutate(credentials);
  //   }
  // };
  const handleSubmit = () => {
    loginMutation.mutate({
      loginType: inputType,
      emailOrPhone: inputValue,
      password,
    });
  };

  const handleForgotPassword = () => {
    setIsOpenForgotPassword(true);
  };

  if (isOpenForgotPassword) {
    return <ForgotPassword setIsOpenForgotPassword={setIsOpenForgotPassword} />;
  }

  return (
    <div className="page_login">
      <div className="content_login">
        <h1>Sing In</h1>
        <div>
          <div>
            <label className="login-label">Login</label>
          </div>
          <input
            className="login-input"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter email or phone"
          />
        </div>
        <div className="password-container">
          <div>
            <label className="password-label">Password</label>
          </div>
          <input
            className="login-input"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
          <img
            src={passwordVisible ? eyeOn : eyeOff}
            alt={passwordVisible ? "Hide password" : "Show password"}
            className="eye-password"
            onClick={() => setPasswordVisible(!passwordVisible)}
            role="button"
            aria-pressed={passwordVisible}
          />
          <div className="forgot-password">
            <button onClick={handleForgotPassword}>Forgot password?</button>
          </div>
        </div>

        <button className="login-btn" onClick={handleSubmit}>
          Sign In
        </button>
        <span onClick={() => navigate("/register")}>
          Don't have an account? Sing Up
        </span>
      </div>
      <div className="bottom-container">
        <span>Or continue with</span>
        <TelegramLoginButton />
      </div>
    </div>
  );
};

export default Login;
