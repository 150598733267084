import BalancePlate from '../../components/balancePalte/BalancePlate';
import useGetUserData from '../../hooks/useGetUserData';
import BalanceController from '../../components/balanceController/balanceController';
import WithdrawPlate from '../../components/withdrawPlate/withdrawPlate';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppState } from '../../routes/Root';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import './BalancePage.css';
import { useAuthToken } from '../../zustand/authStore';
import useGetBalances from '../../hooks/useGetBalances';
import useGetWithdrawals from '../../hooks/useGetHistory';
import Loading from '../../components/loader/Loading';
import HeaderComponent from '../../components/HeaderComponent';

export default function BalancePage() {
    axiosRetry(axios, {
        retryDelay: (retryCount) => {
            return retryCount * 5000;
        },
    });
    // const { token } = useAuthToken((state) => state);
    const token = localStorage.getItem('TokenBearer'); 
    const context = useContext(AppState);
    const navigate = useNavigate();
    const location = useLocation();
    const { data: data } = useGetBalances(token);
    const { data: profileData } = useGetUserData(token);
    const history = useGetWithdrawals(token);
    const [compState, setCompState] = useState({
        active_controller: 'current',
        arrayBalance: [],
        arrayWithdraws: [],
        profileFee: null,
    });
    function setActiveWithdraw(value) {
      if (history?.data?.data) {
            setCompState({
                ...compState,
                active_controller: value,
                arrayWithdraws: history.data.data,
            });
        }
    }
    function backStory() {
        navigate('/', { replace: true });
    }

    function objectMap() {
        let tempArr = [];
        for (let element in data?.data.crypto) {
            tempArr.push(
                <BalancePlate
                    value={element}
                    fee={profileData.fee_data}
                    amount={data.data.crypto[element]}
                />
            );
        }
        for (let element in data?.data.paypal) {
            tempArr.push(
                // <BalancePlate
                //     value={element}
                //     fee={profileData.fee_data}
                //     amount={data.data.paypal[element]}
                // />
            );
        }
        return tempArr;
    }
    function formatingNumber(value) {
        return new Intl.NumberFormat('en-US').format(value);
    }
    useEffect(() => {
        if (profileData !== null && data !== null) {
            setCompState({
                ...compState,
                arrayBalance: data?.data,
                profileFee: profileData?.fee_data,
            });
        }
        axios
            .get('https://api.sprite-ps.com/consolidated_balance?target_currency=USD', {
                headers: {
                    Authorization: localStorage.getItem('TokenBearer'),
                },
            })
            .then((response) => {
                context.dispatch({ type: 'add_user_balance', payload: response.data.sum_balances });
            })
            .catch((error) => console.log(error.status));
    }, []);
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])

    
    return (  
        <div className="mainFrame_balance-page block">
            <HeaderComponent redirectToHome>Balance</HeaderComponent>
            <div className="mainFrame_balance-page-utility">
                <div className="balance-page-utility_left">
                    <div>
                        <span className="mainFraim_balance-balance">Est Stacking Asset</span>
                        <span className="mainFraim_balance-total" style={{ whiteSpace: 'nowrap' }}>
                            ≈{' '}
                            {context.data !== undefined
                                ? formatingNumber(context.data.userBalance)
                                : 0}{' '}
                            USD
                        </span>
                    </div>
                    <div>
                        <span className="mainFraim_balance-balance">Сoefficient</span>
                        <span className="mainFraim_balance-total-alter">
                            {' '}
                            {profileData !== null ? profileData?.fee_data.turnover_rate : 0}
                        </span>
                    </div>
                </div>
                <div className="balance-page-utility_right">
                    {/* <div>
                        <span className="mainFraim_balance-balance">24 hours Profit</span>
                        <span className="mainFraim_balance-total-alter"> 0 USD</span>
                    </div> */}
                </div>
            </div>
            <BalanceController setActive={setActiveWithdraw} />
            {profileData && data ? (
                compState.active_controller === 'current' &&
                data !== null &&
                profileData !== null ? (
                    objectMap()
                )  
                : ( 
                    <div className="mainFrame_lastActiviti-body"> 
                        {compState.arrayWithdraws.map((element, index) => {
                            return <WithdrawPlate key={index} data={element} />;
                        })}
                    </div>
                )
            ) : (
                <Loading />
            )}
        </div>
    );
}
