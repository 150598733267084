import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import eyeOn from '../../assets/eyePassword/eyeOn.svg';
import eyeOff from '../../assets/eyePassword/eyeOff.svg';
import './Registration.css';
import { register } from '../../api/auth/auth'; 
import OtpVerefication from '../otoVerefication/otpVerefication';

const Registration = () => {
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [inputType, setInputType] = useState('email');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isOpenOtp, setIsOpenOtp] = useState(false);

    // Регулярные выражения для проверки
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[1-9]\d{9,14}$/; 
    // const phoneRegex = /^(\+?[0-9]{1,3}[-\s]?)?(\(?\d{1,4}\)?[-\s]?)?[\d\s-]{7,15}$/;


    const registerMutation = useMutation({
      mutationKey: ['register'],
      mutationFn: register,
      retry: (failureCount, error) => {
          // Проверяем, если в ответе ошибка detail
          const errorMessage = error.response?.data?.detail;
          if (errorMessage) {
              toast.error(errorMessage, { autoClose: 3000 });
              return false;  // Прерываем ретрай
          }
          if (failureCount < 3) {
              // toast.error('Something went wrong. Retrying...', { autoClose: 3000 });
              return true;  // Повторяем попытку
          }
          return false;  // Если не нужно пробовать повторно
      },
      onSuccess: (data) => {
          toast.success('Sign up successful', { autoClose: 3000 });
          localStorage.setItem('token', data.token);
          setIsOpenOtp(true);
      },
      onError: (error) => {
          // Это место уже не сработает, если ответ содержит error.detail
          const errorMessage = error.response?.data?.detail;
          if (!errorMessage) {
              toast.error('Something went wrong. Please try again.', { autoClose: 3000 });
          }
      },
  });
   

    const handleInputChange = (e) => {
        const value = e.target.value;

        

        setInputValue(value);

        // Определение типа ввода
        if (emailRegex.test(value)) {
            setInputType('email');
        } else if (phoneRegex.test(value) && value.length > 0) {
            setInputType('phone');
        } else {
            setInputType('unknown');
        }
    };

    const validateInput = () => {
        if (!inputValue || !password) {
            toast.error('Please enter login and password', { autoClose: 3000 });
            return false;
        }

        if (inputType === 'email') {
            if (!emailRegex.test(inputValue)) {
                toast.error('Please enter a valid email', { autoClose: 3000 });
                return false;
            }
        }

        if (inputType === 'phone') {
            if (!phoneRegex.test(inputValue)) {
                toast.error('Please enter a valid phone number', { autoClose: 3000 });
                return false;
            }
            if (inputValue.length < 10 || inputValue.length > 15) {
                toast.error('Phone number must be between 10 and 15 digits', {
                    autoClose: 3000,
                });
                return false;
            }
        }

        if (inputType === 'unknown') {
            toast.error('Please enter a valid email or phone number', {
                autoClose: 3000,
            });
            return false;
        }

        return true;
    };
    const handleSubmit = (e) => {
    
            const credentials = {
                loginType: inputType,
                emailOrPhone: inputValue,
                password,
            };
            registerMutation.mutate(credentials); 
       
    };

    if (isOpenOtp) {
        return (
            <OtpVerefication
                inputType={inputType}
                inputValue={inputValue}
                resetPassword={false}  
            />
        );
    }

    return (
        <div className="page_registr">
            <div className="content_registr">
                <h1>Sing Up</h1>
                <div>
                    <div>
                        <label className="registr-label">Login</label>
                    </div>
                    <input
                        className="registr-input"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Enter email or phone"
                    />
                </div>
                <div className="password-container">
                    <div>
                        <label className="password-label">Password</label>
                    </div>
                    <input
                        className="registr-input"
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                    />
                    <img
                        src={passwordVisible ? eyeOn : eyeOff}
                        alt={passwordVisible ? 'Hide password' : 'Show password'}
                        className="eye-password"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        role="button"
                        aria-pressed={passwordVisible}
                    />
                </div>

                <button className="registr-btn" onClick={handleSubmit}>
                    Sing Up
                </button>

                <span onClick={() => navigate('/login')}>Already have an account? Sing In</span>
            </div>
        </div>
    );
};

export default Registration;
