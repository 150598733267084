import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import OtpVerefication from '../otoVerefication/otpVerefication';
import { useAuthToken } from '../../zustand/authStore';

import eyeOn from '../../assets/eyePassword/eyeOn.svg';
import eyeOff from '../../assets/eyePassword/eyeOff.svg';
import './ForgotPassword.css';
import { reset_password } from '../../api/auth/auth';

const ForgotPassword = ({ setIsOpenForgotPassword }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputType, setInputType] = useState('unknown');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isOpenOtp, setIsOpenOtp] = useState(false);

    // Регулярные выражения для проверки
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\+?[1-9]\d{9,14}$/;

    const forgotPasswordMutation = useMutation({
        mutationKey: ['reset_password'],
        mutationFn: reset_password,
        retry: 3,
        onSuccess: (data) => {
            toast.success('Sent otp', { autoClose: 3000 });
            localStorage.setItem('TokenBearer', data.token);
            // useAuthToken.getState().useSetToken(token);
            setIsOpenOtp(true);
        },
        onError: (error) => {
          const errorMessage = error.response?.data?.detail || error.response?.data?.message || 'Something went wrong';
          toast.error(errorMessage, { autoClose: 3000 });
      },
      
    });

    const HeandleNewPassword = () => {
        if (password.length < 6)
            return toast.error('Password must be at least 6 characters', {
                autoClose: 5000,
            });
        if (password !== newPassword) {
            return toast.error('Passwords do not match', { autoClose: 5000 });
        } else {
           
                const credentials = {
                    loginType: inputType,
                    emailOrPhone: inputValue,
                    password,
                };
                console.log(credentials);
                forgotPasswordMutation.mutate(credentials);
                // return toast.success('Password changed successfully', {
                //     autoClose: 3000,
                // });
        
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        // value = value.replace(/\D/g, '');
        setInputValue(value); 
        // Определение типа ввода
        if (emailRegex.test(value)) {
            setInputType('email');
        } else if (phoneRegex.test(value) && value.length > 0) {
            setInputType('phone');
        } else {
            setInputType('unknown');
        }
    };

    // const validateInput = () => {
    //     if (!inputValue || !password) {
    //         toast.error('Please enter login and password', { autoClose: 3000 });
    //         return false;
    //     }

    //     if (inputType === 'email') {
    //         if (!emailRegex.test(inputValue)) {
    //             toast.error('Please enter a valid email', { autoClose: 3000 });
    //             return false;
    //         }
    //     }

    //     if (inputType === 'phone') {
    //         if (!phoneRegex.test(inputValue)) {
    //             toast.error('Please enter a valid phone number', { autoClose: 3000 });
    //             return false;
    //         }
    //         if (inputValue.length < 10 || inputValue.length > 15) {
    //             toast.error('Phone number must be between 10 and 15 digits', {
    //                 autoClose: 3000,
    //             });
    //             return false;
    //         }
    //     }

    //     if (inputType === 'unknown') {
    //         toast.error('Please enter a valid email or phone number', {
    //             autoClose: 3000,
    //         });
    //         return false;
    //     }

    //     return true;
    // };

    if (isOpenOtp) {
        return (
            <OtpVerefication inputType={inputType} inputValue={inputValue} resetPassword={true} />
        );
    }

    return (
        <div className="page_forgot_password">
            <div className="content_forgot_password">
                <h1>Forgot Password</h1>
                <div>
                    <div>
                        <label className="login-label">Login</label>
                    </div>
                    <input
                        className="login-input"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Enter email or phone"
                    />
                </div>
                <div className="password-container">
                    <div>
                        <label className="password-label">Password</label>
                    </div>
                    <input
                        className="login-input"
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter New Password"
                    />
                    <img
                        src={passwordVisible ? eyeOn : eyeOff}
                        alt={passwordVisible ? 'Hide password' : 'Show password'}
                        className="eye-password"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        role="button"
                        aria-pressed={passwordVisible}
                    />
                </div>

                <div className="password-container-confirm">
                    <div>
                        <label className="password-label">Confirm Password</label>
                    </div>

                    <input
                        className="login-input"
                        type={passwordVisible ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter Confirm Password"
                    />
                    <img
                        src={passwordVisible ? eyeOn : eyeOff}
                        alt={passwordVisible ? 'Hide password' : 'Show password'}
                        className="eye-password-confirm"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        role="button"
                        aria-pressed={passwordVisible}
                    />
                </div>
                <button className="login-btn" onClick={HeandleNewPassword}>
                    Save new password
                </button>
                <button className="login-btn" onClick={() => setIsOpenForgotPassword(false)}>
                    Back
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;
