import ActivetyPlate from "../../components/activetyPlate/activetyPlate"
import PaymentInvoce from "../../components/paymentDone/PaymentInvoce"
import Calendar from 'react-calendar'
import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react"
import { AppState } from "../../routes/Root"
import calendar from "../../img/calendar.svg"
import { useQuery } from '@tanstack/react-query'
import "./calendar.css"
import "./LastTransactionsPage.css"
import 'react-calendar/dist/Calendar.css'
import { useSortCurrency } from "../../zustand/sortValues";
import { useGetLastTransactions } from "../../hooks/useGetLastTransactions";
import { useAuthToken } from "../../zustand/authStore";
import { useSortTransStore } from "../../zustand/sortTransStore";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { BASE_URL } from "../../static/static";
import Loading from "../../components/loader/Loading";
import NoTransactions from "../../components/loader/NoTransactions";


function splittingRange(value) {
    let dayAndTime = JSON.parse(value).split("T")
    let time = dayAndTime[1].split(".")
    let day = dayAndTime[0].split("-")
    let rightData = ""
    rightData = day[2] + "-" + day[1] + "-" + day[0]
    return rightData
}


export default function LastActivetyPage() {
    const [outerBlob, setOuterBlob] = useState()
    const getBlob = async (token) => {
        const response = await axios.get(`${BASE_URL}/orders_report`,
            {
                responseType: 'blob',
                headers: {
                    "Authorization":token
                }
            }
        )
        console.log(response.data)
        setOuterBlob( {blob: URL.createObjectURL(response.data)})
        return response.data
    }
    // const {token} = useAuthToken((state)=>state)
 
    const token = localStorage.getItem('TokenBearer');
        
    const getOrder = useQuery({
        queryKey:['order_report'],
        queryFn:()=>getBlob(token),
        enabled:!!token
    })

    const [page, setPage] = useState(1)
    console.log('PAge', page)
    const {data: currencies} = useGetCurrencies(token)
    console.log(currencies?.data)
    const {active_sortTrans, setSortTrans} = useSortTransStore((state)=>state)
    
    const {currency, setSortCurrency} = useSortCurrency((state)=>state)
    
    const [selectFilter, setSelectFilter] = useState(active_sortTrans==true ? 'Filled':
        active_sortTrans==false ? 'Unfilled' : 'All')
    
    const context = useContext(AppState)
    const navigate = useNavigate()
    const location = useLocation()
    const [compState, setCompState] = useState({
        calendarToggle: false,
        requestRange: [],
        requestReady: false,
        startDate: "",
        endDate: "",
        data: [],
        page: 1,
        blob: null,
        nextPage: false
    })
    console.log('requesting range', compState.requestRange) 
    const {data:lastTransactions, isLoading} = useGetLastTransactions(token, active_sortTrans, page, compState.requestRange, currency)
    // function getSliceOfTransactions() {
    //     axios.get(`https://api.sprite-ps.com/last_transactions?start_date=${compState.requestRange[0]}&end_date=${compState.requestRange[1]}`,
    //         {
    //             headers: {
    //                 "Authorization": localStorage.getItem("TokenBearer")
    //             }
    //         }
    //     ).then(response => {
    //         if (response.status === 200) {
    //             setCompState({ ...compState, calendarToggle: false, data: response.data.data, nextPage: response.data.next_page })
    //         }
    //         return response.data
    //     }).catch(error => console.log(error))
    // }
    function backStory() {
        navigate("/", { replace: true })
    }

    function selectingRange(value) {
        let formingData = []
        value.map(element =>
            formingData.push(splittingRange(JSON.stringify(element)))
        )
        setCompState({
            ...compState,
            calendarToggle: true,
            requestRange: formingData,
            requestReady: true
        })
    }

    function getLastTransactionsGetMoar() {
        if (compState.nextPage) {
            axios(`https://api.sprite-ps.com/last_transactions?last_transactions?page=${compState.page + 1}`, {
                method: "GET",
                headers: {
                    "Authorization": localStorage.getItem("TokenBearer")
                }
            })
                .then(response => setCompState({ ...compState, data: [...compState.data, ...response.data.data], nextPage: response.data.next_page }))
                .catch(error => navigate("/fail_auth"))
        } else {
            console.log("Nothing to download")
            return false
        }
    }
    function toggleCalendar() {
        setCompState({ ...compState, calendarToggle: !compState.calendarToggle })
    }
    function getAlltransactions() {
        try {
            axios.get("https://api.sprite-ps.com/orders_report",
                {
                    responseType: 'blob',
                    headers: {
                        "Authorization": localStorage.getItem("TokenBearer")
                    }
                }
            )
                .then(response => setCompState({ ...compState, blob: URL.createObjectURL(response.data) }))
                .catch(error => console.log(error.status))

        } catch {
            console.warn("error")
        }
    }
    
    // useEffect(() => {
    //     axios.get("https://api.sprite-ps.com/last_transactions", {
    //         headers: {
    //             "Authorization": localStorage.getItem("TokenBearer")
    //         }
    //     })
    //         .then(response => {
    //             context.dispatch({ type: "add_last_transactions", payload: response.data })
    //         }).catch(error => console.log(error.status))
    //     getBlob()
    // }, [])
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])

    const sort_currencies = [
        'All',
        'Tether',
        'Tron',
        'Ethereum'
    ]
    const status_order = [
        'All',
        'Filled',
        'Unfilled',
    ]
    const handleChooseStatus = (item) => {
        if(item==="Filled"){
            setSortTrans(true)
            setSelectFilter(item)
        } else if(item === "Unfilled"){
            setSortTrans(false)
            setSelectFilter(item)
        } else {
            setSortTrans(null)
            setSelectFilter('All')
        }
    }
    const handleChooseCurrency = (item) => {
        setPage(1)
        if(item === "All"){
            setSortCurrency('')
        }
        setSortCurrency(item)
    }
console.log('compState ', compState.data)
    
const filteredArray = lastTransactions?.data?.filter(el=> currency === el.payment_method.currency || currency=='All')
    
    console.log('FilteredArray', filteredArray)
    const onPrev = () => {
        if(page === 1) {
            return
        }

        setPage(prev=>--prev)
    }
    const onNext = () => {
        if(!lastTransactions?.next_page) {
            return
        }
        // console.log('last transactions page', lastTransactions.next_page)

        setPage(prev=>++prev)
    }
    console.log(filteredArray)
    console.log(currency)
    return (
        <>
            {context.data.invose ?
                <PaymentInvoce /> : null
            }
            <div className="mainFrame_lastActiviti-page block">
                <div className="mainFrame_lastActiviti-header-alter">
                    <div className="mainFrame_header-extra">
                        <h1>
                            Last Transactions
                        </h1>
                    </div>
                    <div onClick={() => toggleCalendar()} className={compState.calendarToggle ? "footer-btn active-calendar" : "footer-btn"}>
                        <img className="button-img" src={calendar} alt="calendar" />
                    </div>
                    
                    {compState.calendarToggle ?
                        <div className="calendar-wrapper">
                            <Calendar onChange={(value) => selectingRange(value)}
                                calendarType="iso8601"
                                selectRange={true}
                                locale="en-EN"
                                className="lastActivety-calendar"
                            />
                            <a className="btn-done block calendar-btn"   onClick={()=> setCompState({ ...compState, calendarToggle: false })}>
                                {compState.requestRange.length > 0 ?
                                    "Show " + compState.requestRange[0].split(" ")[0] + " to " + compState.requestRange[1].split(" ")[0]
                                    : "Select range "
                                }
                            </a>
                            {
                                compState.requestRange.length > 0 && 
                                <a className="btn-repeat block calendar-btn" style={{width:'75%'}} onClick={()=>setCompState({...compState, data:[], calendarToggle:false, requestRange:[ ]})} >
                                    Remove data filter
                                </a>
                            }
                        </div>
                        : null}
                </div>
                
                <div className="mainFrame_lastActiviti-sort">
                        {
                            currencies &&
                            <a 
                                className={currency === 'All' ? 'active' : ''}
                                onClick={()=>handleChooseCurrency('All')}
                            >
                                All
                            </a>
                            
                        }
                    {   
                        currencies &&
                        currencies.data.map((item,index) =>
                        <a 
                            key={index}
                            className={currency === item ? 'active' : ''}
                            onClick={()=>handleChooseCurrency(item)}
                        >
                            {item}
                        </a>)
                    }
                </div>
                <div className="mainFrame_lastActiviti-sort">
                    {
                        status_order.map((item,index) =>
                        <a 
                            key={index}
                            className={selectFilter === item ? 'active' : ''}
                            onClick={()=>handleChooseStatus(item)}
                        >
                            {item}
                        </a>)
                    }
                </div>
                {
                    lastTransactions &&
                    
                    filteredArray.length > 0 ?
                    
                    filteredArray.map((element, index) =>    
                        <ActivetyPlate key={index} data={element} />
                    )
                    :
                    isLoading ?
                        <Loading/>
                    :
                        <NoTransactions/>
                }
                {
                    lastTransactions &&
                    <div className="btn-container">
                        <div className="single-btn">
                            {
                                page > 1 &&
                                <button onClick={onPrev} disabled={page === 1 ? true : false} className="btn-repeat">Prev</button>

                            }
                        </div>
                        <div className="single-btn">
                            {
                                lastTransactions.next_page &&
                                <button onClick={onNext} disabled={lastTransactions?.next_page ? false : true} className="btn-repeat">Next</button>
                            }
                        </div>
                    </div>

                }
            </div>
            {
                outerBlob &&
                <a className="btn-done lastActivety-footer block" href={outerBlob?.blob} download >
                    Download all transactions
                </a>
            }
        </>
    )


}