import { useEffect, useState } from 'react';

const useTelegramWebview = () => {
    const [isTgApp, setIsTgApp] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        // Проверка на Telegram Webview для Android
        const isAndroid = /android/.test(userAgent);
        const isTgAndroid = isAndroid && userAgent.includes('telegram');
        // Проверка на Telegram Webview для iOS
        const isIos = /iphone|ipad|ipod/.test(userAgent);
        const isTgIos =
            isIos &&
            (typeof window.TelegramWebviewProxy !== 'undefined' ||
                typeof window.TelegramWebviewProxyProto !== 'undefined');

        // Если это Telegram Webview на Android или iOS
        if (isTgAndroid || isTgIos) {
            setIsTgApp(true);
        } else {
            setIsTgApp(false);
        }
    }, []);

    return isTgApp;
};

export default useTelegramWebview;
