// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' || 
    window.location.hostname === '127.0.0.1'
);
 
export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log("Service Worker зарегистрирован:", registration);
        })
        .catch((error) => {
          console.error("Ошибка при регистрации Service Worker:", error);
        });
    });
  }
}

