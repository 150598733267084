import React, { useEffect, useState } from 'react';

import styles from './InstallPWA.module.scss';
import plus from '../../img/plus.svg';

const InstallPWA = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const isStandalone =
            window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

        const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

        const dismissed = localStorage.getItem('pwa-dismissed');

        if (isStandalone || dismissed) {
            return;
        }

        if (isIOS) {
            console.log('aos');

            if (!localStorage.getItem('ios-alert-shown')) {
                alert(
                    'Для установки приложения откройте Safari, нажмите "Поделиться" и выберите "На экран Домой".'
                );
                localStorage.setItem('ios-alert-shown', 'true');
            }
            return;
        } else {
            const handler = (event) => {
                event.preventDefault();
                setDeferredPrompt(event);
                setShowModal(true);
            };

            window.addEventListener('beforeinstallprompt', handler);

            return () => {
                window.removeEventListener('beforeinstallprompt', handler);
            };
        }
    }, []);

    const installApp = async () => {
        // const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

        if (!deferredPrompt) return;

        deferredPrompt?.prompt();
        const { outcome } = await deferredPrompt.userChoice;

        if (outcome === 'accepted') {
            console.log('PWA установлено');
        } else {
            console.log('Пользователь отклонил установку');
            localStorage.setItem('pwa-dismissed', 'true');
        }

        setDeferredPrompt(null);
        setShowModal(false);
    };

    const closeModal = () => {
        setShowModal(false);
        localStorage.setItem('pwa-dismissed', 'true'); // Запоминаем отказ
    };

    return (
        showModal && (
            <div className={styles.overlay}>
                <div className={styles.modal}>
                    <h2 className={styles.title}>Установить приложение</h2>
                    <div>
                        <button onClick={installApp} className={styles.button}>
                            Установить
                        </button>
                        <button onClick={closeModal} className={styles.cancelButton}>
                            <img className={styles.cancelButtonImg} src={plus} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default InstallPWA;
