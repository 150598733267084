import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import styles from './ChangeProject.module.scss';
import SelectorMenu from '../SelectorMenu';
import { options } from '../CreateProject/data';
import HeaderComponent from '../HeaderComponent';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BASE_URL } from '../../static/static';
import { useAuthToken } from '../../zustand/authStore';
import { getCurrenciese } from '../../api/Api'; 

const ChangeProject = () => {
    const [form, setForm] = useState({ name: '', link: '', description: '', send_post_url: '' });
    
    const [popup, setPopup] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [walletse, setWalletse] = useState([]);
    // const authToken = useAuthToken((state) => state.token);
    const authToken = localStorage.getItem('TokenBearer'); 
const navigate = useNavigate();

    const linkState = useLocation();
    const state = linkState.state.state;
    useEffect(() => {
      setForm({
          name: state.name,
          link: state.link,
          description: state.description,
          send_post_url: state.send_post_url,
      });
      setWallets(state.currency); // Устанавливаем валюту из состояния
  }, [state]); 
 
  const [isChecked, setIsChecked] = useState(state.status);
   

    console.log(state, 'linkState');
    console.log(state, 'chnggggggggggg');
    const { mutate } = useMutation({ 
        mutationKey: ['change-project'], 
        mutationFn: async (changeData) => {
            const responce = await axios.post(`${BASE_URL}/services_edit`, changeData.data, {
                params: {
                    service_id: changeData.id, 
                },
                headers: { 
                  "Authorization": localStorage.getItem("TokenBearer")
              }, 

            });

            return responce.data;
        },

        onSuccess: () => {
          toast.success('Project Updated');
          navigate('/my_projects'); // Перенаправление на страницу /my_projects

          window.location.reload();
      }, 

        onError: (error) => {
            toast.error('Error updating project');
            console.log('Error updating project', error);
        },
    });

    const handleToggle = () => {
        setIsChecked((isChecked) => !isChecked);
    };

    const handlePopup = () => {
        setPopup(!popup);
    };
    const formatWallets = (wallets) => {
        if (wallets.length === 0) {
            return (
                <span onClick={handlePopup} className={styles.empty}>
                    Choose currency  
                </span> 
            );
        } else {
            if (wallets.length > 3) {
                return wallets.slice(0, 3).join(', ') + '...';
            } else {
                return wallets.join(', ');
            }
        }
    };
 
    const submit = () => {
      console.log(wallets, 'wallets before submit'); 
        if (!form.name || !form.link || !form.description || !form.send_post_url) {
            toast.error('Fill all fields');
            return;
        }

        if (form.name) {
            if (form.name.length < 2) {
                toast.error('Enter at least two characters');
                return;
            }
        }

        if (form.link) {
            if (!/^https:\/\//.test(form.link)) {
                toast.error('Enter valid Link');
                return;
            }
        }

        if (form.description) {
            if (form.description.length < 10) {
                toast.error('Enter at least ten characters');
                return;
            }
        }

        if (form.send_post_url) {
            if (!/^https:\/\//.test(form.send_post_url)) {
                toast.error('Enter valid URL');
                return;
            }
        }
        console.log('Data being sent:', {
          ...form,
          status: isChecked,
          currency: wallets,
      });
    
        mutate({
            data: {
                ...form,
                status: isChecked, 
                currency: wallets,
            },
            token: authToken,
            id: state.id,
        });
    };


    useEffect(() => {
      const fetchCurrencies = async () => { 
          try {
              const currencies = await getCurrenciese(); // Получаем валюты
              setWalletse(currencies.map(currency => currency.currency)); // Обновляем состояние wallets
          } catch (error) {
              toast.error('Failed to fetch currencies');
          } 
      };

      fetchCurrencies();
  }, []);

    return (
        <>
            <div className={styles.editHead}>
                <HeaderComponent>Service</HeaderComponent>
            </div>

            <div className={styles.form}>
                <div className="profile_first-name ">
                    <p>Project Name:</p>  
                    <input
                        type="text"
                        onChange={(e) => setForm({ ...form, name: e.target.value })}
                        className="invoice-input"
                        placeholder={'My project name'}
                        value={form.name}
                    />
                </div>

                <div className="profile_last-name ">
                    <p>Project Description:</p>
                    <input
                        type="text"
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                        className="invoice-input"
                        placeholder={'My project description'}
                        value={form.description}
                    />
                </div>

                <div className="profile_middle-name ">
                    <p>Project Link:</p>
                    <input
                        type="text"
                        onChange={(e) => setForm({ ...form, link: e.target.value })}
                        className="invoice-input"
                        placeholder={'https://...'}
                        value={form.link}
                    />
                </div>

                <div className="profile_last-name ">
                    <p>Project callback URL:</p>
                    <input
                        type="text" 
                        onChange={(e) => setForm({ ...form, send_post_url: e.target.value })}
                        className="invoice-input"
                        placeholder={'https://...'}
                        value={form.send_post_url}
                    />
                </div>

                {popup ? (
                    <div className={styles.wallDiv}>
                        <span className={styles.wallOpener} onClick={handlePopup}>
                            Currency:{' '}
                        </span>
                        <span onClick={handlePopup} className={styles.walletsSpan}>
                            {formatWallets(wallets)}
                        </span>
                        <div className={styles.walletLine}></div> 
                        <SelectorMenu
                            walls={wallets}  
                            setWall={setWallets}
                            options={walletse}  
                            popopSet={setPopup}
                        />
                    </div>
                ) : (
                    <div className={styles.wallDiv}>
                        <span className={styles.wallOpener} onClick={handlePopup}>
                            Currency:{' '}
                        </span>
                        <span onClick={handlePopup} className={styles.walletsSpan}>
                            {formatWallets(wallets)}
                        </span>
                        <div className={styles.walletLine}></div>
                    </div>
                )}

                <div className={styles.checkedMenu}>
                    <div className={styles.statusDiv}>
                        <span onClick={handleToggle} className={styles.status}>
                            Status:
                        </span>
                        <span
                            onClick={handleToggle}
                            className={isChecked ? styles.formStatus : styles.formStatusOff}
                        >
                            {isChecked ? 'active' : 'disabled'}
                        </span>
                    </div>
                </div>

                <div className={styles.btnAccept}>
                    <button onClick={submit} className={`btn-done ${styles.animateBtn}`}>
                        Save Changes
                    </button>
                </div>
            </div>
        </>
    ); 
};

export default ChangeProject;
