import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { confirm_set_phone_or_email, resend_otp } from "../../api/auth/auth";
import { toast } from "react-toastify";
import "./otoVerefications";    
// import Back from "./Back"; 
// import useTelegramWebview from "../../utils/useTelegramWebview";
 
const OtpVerefications = ({
  inputType,
  inputValue,
  // resetPassword, 
  setIsOpenOtp,
}) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(10);
  const [, setCanResend] = useState(false);
  const navigate = useNavigate();
  // const isTgApp = useTelegramWebview();

  useEffect(() => {
    if (timeLeft <= 0) {
      setCanResend(true);
      return;
    }
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [timeLeft]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input if a character is entered
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    } 
  };

  const VerifiedMutation = useMutation({
    mutationKey: ["confirm_set_phone_or_email"], 
    mutationFn: confirm_set_phone_or_email,
    retry: 3,
    onSuccess: (data) => {
      // if (resetPassword) { 
      //   console.log("resetPassword login", resetPassword);
      //   // window.location.reload();
      // } else { 
      //   // navigate("/", { replace: true });
      // }
      toast.success("OTP verified", { autoClose: 3000 });
      window.location.reload();
    },
    onError: (error) => {
      //Выводим сообщение об ошибке с сервера
      const errorMessage = error.response?.data?.detail || "Something went wrong!";
      toast.error(errorMessage, { autoClose: 3000 });
    },
  });

  const resendOtpMutation = useMutation({
    mutationKey: ["resend_otp"],
    mutationFn: resend_otp,
    retry: 3,
    onSuccess: (data) => {
      toast.success("otp resend", { autoClose: 3000 });
      setTimeLeft(10); 
    }, 
    onError: (error) => {
      toast.error(error.response.data.detail, { autoClose: 3000 });
    },
  });

  const resendOTP = async () => {
    const credentials = {
      loginType: inputType,
      emailOrPhone: inputValue,
    };
    console.log("credentials", credentials);
    resendOtpMutation.mutate(credentials);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const credentials = { 
        otp_type: inputType, // передаем явно
        otp: Number(otp.join("")), 
    };
    VerifiedMutation.mutate(credentials);
};


  return (
    <div className="wrapper">
      <h1 className="title">OTP CODE</h1>
      <div class="form-container">
        <div class="inner-form-container">
          <form onSubmit={handleSubmit} className="form">
            <div className="otp-fields">
              {otp.map((value, index) => (
                <div
                  style={{
                    width: "50px",
                    height: "70px",
                    borderRadius: "5px",
                    overflow: "hidden", 
                  }}
                >
                  <div className="border-rgb">
                    <input
                      key={index}
                      type="text"
                      value={value}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      required
                      className="otp-input"
                      maxLength={1}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  </div>
                </div>
              ))}
            </div>

            {error && <p className="error-message">{error}</p>}

            <button type="submit" className="submit-button">
              <p>Send</p> 
            </button>
          </form>

          {timeLeft > 0 ? (
            <p className="timer-message">
              Get code again in {timeLeft} seconds
            </p>
          ) : (
            <a onClick={resendOTP} className="resend-link">
              <p className="timer-message">Get code again</p>
            </a>
          )}
        </div>
      </div>
    </div> 
  );
};
export default OtpVerefications;
 