import { useQuery } from '@tanstack/react-query';
import { getBalances } from '../api/Api';

export default function useGetBalances(token) {
    return useQuery({
        queryKey: ['balances'],
        queryFn: () => getBalances(token),
        enabled: !!token,
    });
}
