import { useEffect } from 'react';
import { BASE_URL } from '../../static/static';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './TelegramLoginButton.css';
import { useAuthToken } from '../../zustand/authStore';

const TelegramLoginButton = () => {
    const navigate = useNavigate();
    const styles = {
        container: {
            display: 'flex',
            padding: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5dc00',
            fontSize: 'large',
        },
    };

    useEffect(() => {
        const params = getQueryParams();
        if (params.id) {
            handleTelegramAuth(params);
        } else if (params.tgAuthResult) {
            const data = JSON.parse(atob(params.tgAuthResult)); // Расшифровка base64
            handleTelegramAuth(data);
        }
    }, []);

    const getQueryParams = () => {
        return Object.fromEntries(new URLSearchParams(window.location.search));
    };

    //   // Отправка данных Telegram на сервер
    const handleTelegramAuth = async (data) => {
        console.log('userData', data);
        let attempts = 0;
        const maxAttempts = 3;

        while (attempts < maxAttempts) {
            try {
                const response = await axios.post(`${BASE_URL}/login_with_telegram`, data);

                if (response.status === 200) {
                    const token = response.headers['authorization'];
                    const username = data.username;  
                    if (token) { 
                        localStorage.setItem('TokenBearer', token);
                        // localStorage.setItem('TelegramUsername', username); 
                        useAuthToken.getState().useSetToken(token);
                        toast.success('Logged in via Telegram');
                        // setTimeout(() => navigate("/", { replace: true }), 500);
                        navigate('/', { replace: true }); 
                        setTimeout(() => window.location.reload(), 100);

                        return;
                    } else {
                        toast.error('Token not received');
                        return;
                    }
                }
            } catch (error) {
                attempts++;
                console.error(`Attempt ${attempts} failed:`, error); 

                if (attempts >= maxAttempts) {
                    toast.error('Telegram login failed after multiple attempts');
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Увеличиваем задержку между попытками
                }
            }
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://telegram.org/js/telegram-widget.js?22`;
        script.async = true;
        script.setAttribute('data-telegram-login', 'sprite_ps_office_bot');
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-auth-url', `https://app.sprite-ps.com/login`);
        script.setAttribute('data-request-access', 'write');
        script.setAttribute('data-userpic', 'false');

        const container = document.getElementById('telegram-login-container');
        if (container) {
            container.innerHTML = '';
            container.appendChild(script);
        }
    }, []);

    const handleTelegramLogin = () => {
        if (window.Telegram?.Login) {
            window.Telegram.Login.auth({ bot_id: '7489278720', request_access: true }, (data) => {
                if (!data) {
                    toast.error('Telegram login failed');
                    return;
                }
              
                handleTelegramAuth(data);
            });
        } else {
            toast.error('Telegram API не загружен');
        }
    };

    return (
        <div className="flex  justify-center items-center">
            <div id="telegram-login-container"></div>

            <button
                id="telegram-login-container"
                style={styles.container}
                className="tg-btn"
                onClick={handleTelegramLogin}
            >
                Telegram
            </button>
        </div>
    );
};

export default TelegramLoginButton;

// import { useEffect } from "react";
// import { BASE_URL } from "../../static/static";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import "./TelegramLoginButton.css";

// const TelegramLoginButton = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const params = getQueryParams();
//     if (params.id) {
//       handleTelegramAuth(params);
//     } else if (params.tgAuthResult) {
//       const data = JSON.parse(atob(params.tgAuthResult)); // Расшифровка base64
//       handleTelegramAuth(data);
//     }
//   }, []);

//   const getQueryParams = () => {
//     return Object.fromEntries(new URLSearchParams(window.location.search));
//   };

//   // Отправка данных Telegram на сервер
//   const handleTelegramAuth = async (data) => {
//     console.log("userData", data);
//     let attempts = 0;
//     const maxAttempts = 3;

//     while (attempts < maxAttempts) {
//       try {
//         const response = await axios.post(
//           `${BASE_URL}/login_with_telegram`,
//           data
//         );

//         if (response.status === 200) {
//           const token = response.headers["authorization"];
//           if (token) {
//             localStorage.setItem("TokenBearer", token);
//             toast.success("Logged in via Telegram");
//             navigate("/", { replace: true });
//             setTimeout(() => window.location.reload(), 100);

//             return;
//           } else {
//             toast.error("Token not received");
//             return;
//           }
//         }
//       } catch (error) {
//         attempts++;
//         console.error(`Attempt ${attempts} failed:`, error);

//         if (attempts >= maxAttempts) {
//           toast.error("Telegram login failed after multiple attempts");
//         } else {
//           await new Promise((resolve) => setTimeout(resolve, 1000 * attempts)); // Увеличиваем задержку между попытками
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = `https://telegram.org/js/telegram-widget.js?22`;
//     script.async = true;
//     script.setAttribute("data-telegram-login", "sprite_ps_office_bot");
//     script.setAttribute("data-size", "large");
//     script.setAttribute("data-auth-url", `https://app.sprite-ps.com/login`);
//     script.setAttribute("data-request-access", "write");
//     script.setAttribute("data-userpic", "false");

//     const container = document.getElementById("telegram-login-container");
//     if (container) {
//       container.innerHTML = "";
//       container.appendChild(script);
//     }
//   }, []);

//   const handleTelegramLogin = () => {
//     if (window.Telegram?.Login) {
//       window.Telegram.Login.auth(
//         { bot_id: "7489278720", request_access: true },
//         (data) => {
//           if (!data) {
//             toast.error("Telegram login failed");
//             return;
//           }
//           handleTelegramAuth(data);
//         }
//       );
//     } else {
//       toast.error("Telegram API не загружен");
//     }
//   };
//   return (
//     <div className="flex  justify-center items-center">
//       <button
//         // id="telegram-login-container"
//         className="tg-btn"
//         onClick={handleTelegramLogin}
//       >
//         Telegram
//       </button>
//     </div>
//   );
// };

// export default TelegramLoginButton;
