import React, { useState } from 'react';

import styles from './SelectorMenu.module.scss';
const SelectorMenu = ({ walls, setWall, options, popopSet }) => {
    const handleChange = (value) => {
        setWall((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    return (
        <div
            className={styles.blur}
            style={{
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            }}
        >
            <div onClick={() => popopSet(false)} className={styles.closer}>
                +
            </div>
            <div className={styles.selector}>
                {options.map((option) => (
                    <div
                        className={`${styles.opt} ${walls.includes(option) ? styles.select : ''}`}
                        key={option}
                        value={option}
                        onClick={() => handleChange(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectorMenu;
