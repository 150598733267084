import React from "react";
import ReactDOM from "react-dom/client";
import BalancePage from "./pages/balancePage/BalancePage";
import LastTransactions from "./pages/lastActivetyPage/LastTransactionsPage";
import WithdrawPage from "./pages/withdrawPage/WithdrawPage";
import InvoicePage from "./pages/invoicePage/InvoicePage";
import ProjectsPage from "./pages/projectsPage/ProjectsPage";
import SupportPage from "./pages/supportPage/SupportPage";
import EditingProjectPage from "./pages/editingProjectPage/EditingProjectPage";
import CashflowPage from "./pages/cashflowPage/CashflowPage";
import ProfilePage from "./pages/profilePage/ProfilePage";
import FailAuth from "./pages/failAuth/FailAuth";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/Root";
import MainPage from "./pages/mainPage/MainPage";
import Login from "./components/login/Login"; 
import Register from "./components/register/Registration";
import AuthPage from "./pages/authPage/authPage";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { queries } from "@testing-library/react";
import AllInvoices from "./pages/allInvoices/AllInvoices";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools/production";
import OtpVerefication from "./components/otoVerefication/otpVerefication";
import ChangeProject from './components/ChangeProject'; 
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
 
import { register } from './serviceWorkerRegistration.js'; 
 
register(); // Вызовите функцию регистрации
 

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/otp",
        element: <OtpVerefication />,
      },
      {
        path: "/auth",
        element: <AuthPage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/total_balance",
        element: <BalancePage />,
      },
      {
        path: "/fail_auth",
        element: <FailAuth />,
      },
      {
        path: "/total_balance/withdraw",
        element: <WithdrawPage />,
        error: <Root />,
      },
      {
        path: "/last_transactions",
        element: <LastTransactions />,
      },
      {
        path: "/my_projects",
        element: <ProjectsPage />,
      },
      {
        path: "/all_invoices",
        element: <AllInvoices />,
      },
      {
        path: "/my_projects/edit",
        element: <EditingProjectPage />,
      },
      {
        path: '/my_projects/edit/changes',
        element: <ChangeProject />,
    },
      {
        path: "/invoice",
        element: <InvoicePage />,
      },
      {
        path: "/support",
        element: <SupportPage />,
      },
      {
        path: "/cashflow",
        element: <CashflowPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
    ],
  },
]);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
);

// registerServiceWorker();
 
// Для отчета о производительности
 