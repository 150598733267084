import { useMutation } from '@tanstack/react-query';
import { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../static/static';
import { toast } from 'react-toastify';
import { AppState } from '../../routes/Root';

import OtpVerefication from '../otoVerefication/otpVerefication'; 
// {
//     property_type: type,
//     property_value: mail_or_phone, 
// }
const OTPSender = ({ btnNum, type, mail_or_phone, token, setIsOpenOtp  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(AppState);

    const { mutate } = useMutation({  
        mutationKey: ['otp-sender'],
        mutationFn: async (optData) => {
            const responce = await axios.post(`${BASE_URL}/set_phone_or_email`, optData.data, {
                headers: { 
                    Authorization: `${optData.token}`,
                },
            });
            return responce.data;
        },

        onSuccess: () => {
            setIsLoading(false);
            setIsOpenOtp(true);
            toast.success('OTP send');
        },

        onError: (error) => {
          setIsLoading(false);

          // Проверяем, если есть "detail" в ответе
          if (error.response?.data?.detail) {
              toast.error(error.response.data.detail);
          } 
          // Если ошибка с кодом 500
          else if (error.response?.status === 500) {
              toast.error('Unable to connect email/phone');
          } 
          else {
     
              toast.error('Error sending OTP');
              
            
          } 
      },
  });
 

    const otpSender = () => {
        if (isLoading) return;

        setIsLoading(true);
        console.log(mail_or_phone, 'arrrrrs');
        setIsLoading(true);
        mutate({
            data: {
                property_type: type,
                property_value: mail_or_phone,
            },
            token: token,
        });
    };

    function setWallet(newWallet) {
      axios
          .post(
              'https://api.sprite-ps.com/set_wallet',
              { wallet: newWallet },
              {
                  headers: {
                      Authorization: `${localStorage.getItem('TokenBearer')}`,
                  },
              }
          )
          .then((response) => {
              console.log("Response received:", response);
              if (response.status === 200) {
                  context.dispatch({
                      type: 'success_popup',
                      payload: 'Wallet successfully updated!',
                  });
                  console.log("Before state update:", compState);
                  setCompState({ ...compState, saved_wallet_trc: newWallet });
                  console.log("State updated successfully!");
              }
          })
          .catch((error) => {
              console.error("Request failed:", error);
              const errorMsg = error.response?.data?.detail; 
              context.dispatch({
                  type: 'error_popup',
                  payload: errorMsg,
              });
          });
  }
  
  
 
    return (
        <>
            {btnNum === 2 ? (
                <div onClick={otpSender} className="balance-btn btn-change-two btn-anim">
                    OTP 
                </div>
           ) : btnNum === 3 ? (
                <div onClick={otpSender} className="balance-btn btn-change-three btn-anim">
                    OTP
                </div>
                  ) : null}




{
    btnNum === 4 ? (
      <div onClick={() => setWallet(mail_or_phone)} className="balance-btn btn-change-four btn-anim">
      SAVE 
  </div>
  
  
    ) : null
}

        </>
    );
};

export default OTPSender;
