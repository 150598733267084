import ProjetPlate from '../../components/projectPlate/projectPlate';
import useGetUserData from '../../hooks/useGetUserData';
import axios from 'axios';
import { AppState } from '../../routes/Root';
import axiosRetry from 'axios-retry';
import { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProjectsPage.css';
import useGetServices from '../../hooks/useGetServices';
import { useAuthToken } from '../../zustand/authStore';
import Loading from '../../components/loader/Loading';
import HeaderComponent from '../../components/HeaderComponent';
import ChoozeMenu from '../../components/ChoozeMenu';
import CreateProject from '../../components/CreateProject';
import { BASE_URL } from '../../static/static'; 
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

export default function ProjectsPage() {
  const context = useContext(AppState);
  const navigate = useNavigate();
  const location = useLocation();
  const [compState, setCompState] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [tab, setTab] = useState('info');
  
  function backStory() {
      navigate('/', { replace: true });
  }
   const token = localStorage.getItem("TokenBearer")

  // const { token } = useAuthToken((state) => state);

  useEffect(() => {
    if (!token) return;

    setIsLoading(true);
    setIsError(false);

    axios.get(`${BASE_URL}/services`, { 
      headers: {
        "Authorization": localStorage.getItem("TokenBearer")
    } ,
    }) 
    .then(response => {
        console.log(response.data);  // Логируем данные
        setCompState({ data: response.data.data || [] }); 
 
    })
    .catch(error => {
        console.error("Ошибка при загрузке сервисов:", error);
        setIsError(true);
    })
    .finally(() => {
        setIsLoading(false);
    });
}, [token]);




  return (
    <div className="my-projects block"> 
      <div className="my-projects_header">
        <HeaderComponent redirectToHome>My Projects</HeaderComponent>
      </div>
      <ChoozeMenu changeTab={setTab} />
      {tab === 'info' ? (
        <div className="my-projects_list">
    

    {isLoading ? (
    <Loading />
) : compState.data && compState.data.length > 0 ? (
    compState.data.map((element, index) => {
        console.log(element);  // Логируем каждый элемент перед рендером
        return <ProjetPlate key={index} data={element} />;
    })
) : (
    <ErrorMessage>NO PROJECTS</ErrorMessage>
)}
 
 
        </div>
      ) : (
        <CreateProject />
      )}
    </div>
  );
}
