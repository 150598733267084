import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppState } from '../../routes/Root';
import './EditingProjectPage.css';
import HeaderComponent from '../../components/HeaderComponent';
import editLogo from '../../img/edit.svg';
export default function EditingProjectPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const context = useContext(AppState);
    let passedState = useLocation();

    console.log(passedState.state, 'aaaa4444ee');

    function backStory() {
        navigate('/my_projects', { replace: true });
    }
    function shareButtonFunc(event, data, value) {
        event.preventDefault();
        navigator.clipboard.writeText(data);
        context.dispatch({ type: 'success_popup', payload: value });
    }

    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])
    return (
        <div className="editing-project-page block">
            <div className="editing-project-page_title-wrapper">
                <div className="editing-project-page_title">
                    <HeaderComponent>{passedState.state.name}</HeaderComponent>
                </div>
            </div>
            <div className="editing-project-page_content styled-block">
                <Link
                    to={'/my_projects/edit/changes'}
                    state={passedState}
                     

                    className="edit-logo-div balance-plate_leftRight"
                >
                    <img className="edit-logo" src={editLogo} alt="" />
                </Link>
                <div className="edit-project_name">
                    <p>Name :</p>
                    <span
                        onClick={(e) => shareButtonFunc(e, passedState.state.name, 'Name copied')}
                    >
                        {' '}
                        {passedState.state.name}
                    </span>
                </div>

                <div className="edit-project_status">
                    <p>Status:</p>
                    <span
                        className={
                            passedState.state.status
                                ? 'edit-project_status-active'
                                : 'edit-project_status-dis'
                        }
                    >
                        {passedState.state.status ? 'active' : 'disabled'}
                    </span>
                </div>

                <div className="edit-project_description">
                    <p>Description :</p>
                    <span
                        onClick={(e) =>
                            shareButtonFunc(e, passedState.state.description, 'Description copied')
                        }
                    >
                        {' '}
                        {passedState.state.description} 
                    </span>
                </div>

                <div className="edit-project_description">
                <p>Currency :</p>
<span
    onClick={(e) =>
        shareButtonFunc(e, passedState.state.currency, 'Description copied')
    }
>
    {' '}
    {passedState.state.currency?.join(' ') ?? 'Currency'}
</span>
 
                </div>

                <div className="edit-project-page_link">
                    <p>Link :</p>
                    <a
                        className="edit-project-link"
                        onClick={(e) => shareButtonFunc(e, passedState.state.link, 'Link copied')}
                        href={passedState.state.link}
                    >
                        {passedState.state.link}
                    </a>
                </div>

                <div className="edit-project-page_url">
                    <p>Callback URL :</p>
                    <a
                        className="edit-project-url"
                        onClick={(e) =>
                            shareButtonFunc(e, passedState.state.send_post_url, 'URL copied')
                        }
                        href={passedState.state.send_post_url}
                    >
                        {passedState.state.send_post_url ?? 'URL'}
                    </a>
                </div>

                <div className="edit-project_api">
                    <p>Api Key :</p>
                    <span
                        onClick={(e) =>
                            shareButtonFunc(e, passedState.state.api_key, 'Api key copied')
                        }
                    >
                        {' '}
                        {passedState.state.api_key}
                    </span>
                </div>

                <div className="edit-project_key">
                    <p>Secret Key :</p>
                    <span
                        onClick={(e) =>
                            shareButtonFunc(e, passedState.state.secret_key, 'Secret key copied')
                        }
                    >
                        {' '}
                        {passedState.state.secret_key}
                    </span>
                </div>

                <div className="edit-project_token">
                    <p>Jwt Bearer Token* :</p>
                    <span
                        className="edit-project_token-content"
                        onClick={(e) =>
                            shareButtonFunc(
                                e,
                                localStorage.getItem('TokenBearer').split('Bearer ')[1],
                                'Token copied'
                            )
                        }
                    >
                        <span>{localStorage.getItem('TokenBearer').split('Bearer ')[1]}</span>
                        <span className="edit-project_token_warn">
                            (*Bearer token - one per account)
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
}
