import { useState, useContext, useEffect } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import axiosRetry from "axios-retry"
import WrongData from "../../components/wrongData/wrongData"
import "./WithdrawPage.css"
import { AppState } from "../../routes/Root"
import { toast } from "react-toastify";
import HeaderComponent from "../../components/HeaderComponent"

export default function WithdrawPage() {
    axiosRetry(axios, { retries: 5 })
    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(AppState)
    const passedData = useLocation()
    const pageId = useParams()
    const paymentArrayInvoice = [['paypal', "USD"], ['paypal', "EUR"], ['tether', "USDT"], ['ethereum', "ETH"], ['tron', "TRX"]]
    const [pageState, setPageState] = useState({
        inputValue: '',
        balance: passedData.state?.balance || "error",
        currency: passedData.state?.currency,
        activeButton: true,
        withdrawalDetails: null,
        showDetails: false
    })
    function backStory() {
        navigate("/total_balance", { replace: true })
    }


    function changeWithdrae(e) {
        // if (typeof parseInt(e.target.value, 10) === "number") {
        //     if (passedData.state.balance >= parseInt(e.target.value, 10)) {
        //         setPageState({ ...pageState, balance: passedData.state.balance - parseInt(e.target.value, 10), inputValue: parseInt(e.target.value, 10) })
        //     } else {
        //         setPageState({ ...pageState, balance: passedData.state.balance, inputValue: 0 })
        //     }
        // } else {
        //     setPageState({ ...pageState, balance: passedData.state.balance, inputValue: 0 })
        // }
        setPageState({ ...pageState, balance: passedData.state.balance, inputValue: e.target.value })
    }
    function timeOut() {
        setPageState({ ...pageState, activeButton: true })
        setTimeout(() => {
            navigate("/total_balance")
        }, 3000)
    }

    function changeWithdrawalAmount(e) {
      // Сбрасываем детали при изменении суммы
      setPageState({
          ...pageState,
          inputValue: e.target.value,
          showDetails: false, // Скрываем детали, когда меняется сумма
          withdrawalDetails: null // Очищаем детали
      });
  }
    function redirect() {
        setPageState({ ...pageState, activeButton: false })
        let paymentMethod = paymentArrayInvoice.filter(element => element[1].toLocaleLowerCase() === pageState.currency)
        axios.post("https://api.sprite-ps.com/estimate_withdrawal",
            {
                balance_type: paymentMethod[0][0],
                currency: paymentMethod[0][1],
                amount: pageState.inputValue
            },
            {
                headers: {
                    Authorization: localStorage.getItem("TokenBearer")
                }
            }
        )
            .then((response) => {
                setPageState({ ...pageState, activeButton: true, withdrawalDetails: response.data, showDetails: true })
                // context.dispatch({ type: "success_popup", payload: "Withdraw was successful." })
                toast.success("successful.");

            }
            ).catch((error) => {
              setPageState({ ...pageState, activeButton: true });

              if (error.response) {
                  // Если сервер вернул ошибку с сообщением
                  const errorMessage =
                      error.response.data?.detail || "An error occurred";
  
                  toast.error(errorMessage);
                  // context.dispatch({ type: "error_popup", payload: errorMessage });
              } else { 
                  // Ошибки сети или сервера
                  toast.error("Server error");
                  // context.dispatch({ type: "error_popup", payload: "Server error" });
                  
              }
              
                // if (error.status === 402) {
                //     context.dispatch({ type: "error_popup", payload: error.response.data.message })
                //     setPageState({ ...pageState, activeButton: true })
                // } else if (error.status === 504) {
                //     context.dispatch({ type: "error_popup", payload: "Server error" })
                //     setPageState({ ...pageState, activeButton: true })
                // } else {
                //     console.log(error)
                //     context.dispatch({ type: "error_popup", payload: "Select amount" })
                //     setPageState({ ...pageState, activeButton: true })
                // }
            })
    }
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])


    function confirmWithdrawal() {
      if (!pageState.withdrawalDetails) {
          toast.error("No withdrawal details available.");
          return;
      }

      const withdrawalData = {
          withdraw_id: pageState.withdrawalDetails.withdraw_id,
          is_confirmed: true
      }

      axios.post("https://api.sprite-ps.com/confirm_withdraw", withdrawalData, {
          headers: {
              Authorization: localStorage.getItem("TokenBearer")
          }
      })
      .then((response) => {
          toast.success("Withdrawal confirmed.");
          setTimeout(() => {
              navigate("/total_balance");
          }, 2000);
      })
      .catch((error) => {
          if (error.response) {
              const errorMessage = error.response.data?.detail || "An error occurred";
              toast.error(errorMessage);
          } else {
              toast.error("Server error");
          }
      });
  }
    return (
        <>
            {pageState.balance === "error" ? <WrongData /> :
                <div className="mainFraim block">
                    <div className="withdraw-header">   
                     
                        <HeaderComponent  >Withdraw</HeaderComponent>
                    </div>
                    <div className="whithdraw-page-content styled-block">
                        <span> Balance :<br /> {pageState.balance} {passedData.state.walletName}</span>
                        {/* <div className="whithdraw-page-conten-data">
                            <p>Card entry fee: {passedData.state.fee_data.card_fee}%. <br />Deposit fee for <span className="wallet-value">{pageId.id}</span> wallet: {passedData.state.fee_data.crypto_fee}% + network commission.</p>
                        </div>
                        <hr /> */}
                   <div className="withdraw-controller">
    <p>How much do you want to withdraw?*</p>
    <div className="withdraw-input-container">
        <input 
            className="invoice-input" 
            placeholder="0" 
            inputMode="numeric" 
            onChange={changeWithdrawalAmount} 
            value={pageState.inputValue} 
        />
        {/* Показывать кнопку только если введенное значение не пустое и не равно 0 */}
        {pageState.inputValue && pageState.inputValue !== "0" && (
            <button className="btn-dones" onClick={redirect}>Check</button>
        )}
    </div>
    <span className="withdraw-warning">*Withdraw are made Mon-Fri 10-18 Moscow time and may take up to 48 hours</span>
</div>


                        {pageState.withdrawalDetails && (
                                <div className="withdraw-details">
                           <div className="withdraw-detail">
    <strong className="strong-details">Amount:</strong>
    <span>{pageState.withdrawalDetails.amount} {pageState.withdrawalDetails.currency}</span>
</div>
<div className="withdraw-detail">
    <strong className="strong-details">Service Fee:</strong>
    <span>{pageState.withdrawalDetails.service_fee} {pageState.withdrawalDetails.currency}</span>
</div>
<div className="withdraw-detail">
    <strong className="strong-details">Chain Fee:</strong>
    <span>{pageState.withdrawalDetails.chain_fee} {pageState.withdrawalDetails.currency}</span>
</div>
<div className="withdraw-detail total">
    <strong className="strong-details">Total:</strong>
    <span>{pageState.withdrawalDetails.total} {pageState.withdrawalDetails.currency}</span>
</div>
        
                                    <button className="btn-done btn-confirm" onClick={() => confirmWithdrawal()}>Confirm</button>
                                
                                </div>
                                
                            )}
                    </div>
                </div>
            }
        </>
    )
}