import styles from './ErrorMessage.module.css';

const ErrorMessage = ({ children }) => {
    return ( 
        <div className={styles.page}>
            <h1>{children}</h1>
        </div> 
    );
};

export default ErrorMessage;
