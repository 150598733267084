import { useQuery } from "@tanstack/react-query";
import { getServices } from "../api/Api";

export default function useGetServices(token) {
    return useQuery({ 
        queryKey: ['services'],
        queryFn: async () => {
            try {
                return await getServices(token);
            } catch (error) {
                if (error.response?.status === 500) {
                    throw new Error("Сервер временно недоступен");
                }
                throw error;
            }
        },
        enabled: !!token,
        staleTime: 1000 * 60 * 5, // Данные актуальны 5 минут
        cacheTime: 1000 * 60 * 10, // Держим в кэше 10 минут
        retry: (failureCount, error) => {
            if (error.message === "Сервер временно недоступен") {
                return false; // Не делать повторные запросы
            }
            return failureCount < 5; // Максимум 5 попыток
        },
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // 1s, 2s, 4s, 8s, макс. 30s
    });
}
