import { useContext, useEffect, useState } from "react"
import netflix from "../../img/netflix.svg"
import paypal from "../../img/paypal.png"
import usdt from "../../img/USDT.png"
import dollar from '../../img/crypto/binance.png'
import bitcoin from "../../img/crypto/tether.png"
import eth from "../../img/crypto/ethereum.png"
import trx from "../../img/crypto/tron.png"
import PaymentInvoce from "../paymentDone/PaymentInvoce" 
import { AppState } from "../../routes/Root"
import "./withdrawPlate.css"

export default function WithdrawPlate({ data }) {
    const [compState, setCompState] = useState(null)
    const [expanded, setExpanded] = useState(false); 
    const context = useContext(AppState)
    const price = Math.ceil(Math.random() * 100)
    const paymentArrayInvoice = [['paypal', ["USD"]], ['paypal', ["EUR"]], ['tether', ["USDT"]], ['ethereum', ["ETH"]], ['tron', ["TRX"]]]


    useEffect(() => {
      if (data !== undefined) {
          setCompState(data)
          console.log("Обновлённые данные:", data)
      }
  }, [data])
  

    function switchValue(value) {
        switch (value) {
            case "eth":
                return (
                    <div>
                        <img src={eth} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>ETH</div>
                        </div>
                    </div>
                )
            case "eur":
                return ( 
                    <div>
                        <img src={paypal} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>Paypal Euro</div>
                        </div>
                    </div>
                )
            case "usd":
                return (
                    <div>
                        <img src={paypal} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>Paypal USD</div>
                        </div>
                    </div>
                )
            case "usdt":
                return (
                    <div>
                        <img src={usdt} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>USDT</div>
                        </div>
                    </div>)
            case "btc":
                return (
                    <div>
                        <img src={bitcoin} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>BTC</div>
                        </div>
                    </div>)
            case "trx":
                return (
                    <div>
                        <img src={trx} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>TRX</div>
                        </div>
                    </div>)
            case "dollar":
                return (
                    <div>
                        <img src={dollar} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>USD</div>
                        </div>
                    </div>)
            default:
                console.warn("Error in currensy switcher")
        }
    }
    function imgSwitcher(value) {
        switch (value) {
            case "trx":
                return trx
            case "eth":
                return eth
            case "usdt":
                return usdt
            case "paypal":
                return paypal
        }
    }
    function switchCurrency(value) {
        switch (value) {
            case "eth":
                return "ETH"
            case "usdt":
                return "USDT"
            case "btc":
                return "BTC"
            case "trx":
                return "TRX"
            case "usd":
                return "$"
            case "eur":
                return "€"
            default:
                console.log("Error in currency")
        }
    }
    function dataFormating(value, value2, isFilled) {
      let months = ["Jan ", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  
      // Проверяем статус is_filled
      let status = isFilled ? "Success" : "Pending";
  
      if (value === null) {
          let date2 = value2.split(" ")[0];
          let formatedArray2 = date2.split("-");
          return (`${status} ${months[formatedArray2[1] - 1]} ${formatedArray2[0]}, ${formatedArray2[2]} ${value2.split(" ")[1].slice(0, 5)}`);
      } else {
          let date = value.split(" ")[0];
          let formatedArray = date.split("-");
          return (`${status} ${months[formatedArray[1] - 1]} ${formatedArray[0]}, ${formatedArray[2]} ${value.split(" ")[1].slice(0, 5)}`);
      }
  }
  
    function walletFormating(value,) {
        let firstSlice = value.slice(0, 5)
        let lastSlice = value.slice(-5)
        return (firstSlice + "****" + lastSlice)
    }
    useEffect(() => {
        if (data !== undefined) {
            setCompState(data)
        }
    }, [data])
    console.log(data)
    return (
        <>
       <div 
            className={`mainFraim_lastActiviti-body-element styled-block-small ${expanded ? "expanded" : ""}`} 
            onClick={() => setExpanded(!expanded)}
        >
   <div className="mainFraim_lastActiviti-body-leftcols" >
                <div className="mainFraim_lastActiviti-body-leftcol">
                    <img src={imgSwitcher(data.currency)} alt="image" className="image-body-element button-img" />
                    <span>
                        <p>{walletFormating(data.wallet)}</p>
                        <p>{dataFormating(data.filled_date, data.create_date, data.is_filled)}</p>
 
                        {/* <p>Amount: {data.amount}</p>
                        
                        <p>Usdt amount: {data.usdt_amount}</p> */}

             
                        
                    </span>
                </div>
                <div className="activity-subs-price">
                    <span>
                        {/* {switchCurrency(data.currency)} {data.amount_to_approve} */}
                        {data.amount}
                    </span>
                </div>
           </div>

                {/* Дополнительная информация при раскрытии */}
                {expanded && (
                <div className="withdraw-details">
                   
                  <p>From balance: <span className="withdraw-details-p" style={{ textTransform: "uppercase" }}>{data.currency}</span>: {data.amount} ~ USDT: {data.usdt_amount}</p>

                    <p>Wallet: {data.wallet}</p>
                    <p>Currency: <span className="withdraw-details-p" style={{ textTransform: "uppercase" }}>{data.currency}</span></p>

                   
                    <p>System fee: 1% = {data.service_fee}</p>
                    <p>Tranzaction fee: {data.chain_fee} </p>
                    <p>Approved amount: {data.usdt_total}</p>
                </div>
            )}
            </div>
            
        </>
    )
}