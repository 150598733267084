import { useContext, useEffect } from "react"
import { AppState } from "../../routes/Root"
import { useState } from "react"
import axios from "axios"
import "./scrollDowsListProjects.css"

const filters = []
const banksFilters = ["VISA", "MASTERCARD", "МИР"]

export default function ScrollDownListProjects({ array = [], selecting, closingComponent , setServiceId}) {
    const context = useContext(AppState)
    const [compState, setCompState] = useState({
        dropDownActive: false,
        selectedCurrency: "",
        selectFilter: "ALL",
    })

    function gettingProjectData(name, api_key, id) {
        axios(`https://api.sprite-ps.com/available_service_cards?service_id=${id}`, {
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem("TokenBearer")
            }
        })
            .then(response => {
                selecting(name, api_key, id, response.data)
            })
            .catch(error => console.warn(error.status))
    }
    useEffect(() => {
    }, [])
    console.log(array)
    const handleClick = (name,api_key,id) => {
        setServiceId(id)
        selecting(name, api_key, id)

    }
    return (
      <div className="scroll-down-bg">
          <div className="scroll-down-close">
              <span onClick={(e) => closingComponent(e)}>+</span>
          </div>
          <div className="scroll-down-filter-list"></div>
          <ul className="scroll-down-list block">
              {array.length === 0 ? (
                  <li className="no-projects">No projects</li>
              ) : (
                  <>
                      {array.filter(element => element.status).map((element, index) => (
                          <li key={index} onClick={() => handleClick(element.name, element.api_key, element.id)}>
                              {element.name}
                          </li>
                      ))}
                      {array.filter(element => element.status).length === 0 && (
                          <li className="no-projects">No active projects</li>
                      )}
                  </>
              )}
          </ul>
      </div>
  );
   
}